import React from "react";
import PropTypes from "prop-types";
import themeColor from "../../../styles/themeColor";

const IconButton = (props) => {
  const { tagStyle, iconStyle, tag, icon, children } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        src={icon}
        style={{
          aspectRatio: 1,
          width: 60,
          ...iconStyle,
        }}
        alt={tag}
      />
      <span
        style={{
          border: `1px solid ${themeColor.borderColor}`,
          borderRadius: 5,
          background: themeColor.backgroundColor,
          padding: "5px 10px",
          fontSize: "0.6rem",
          flexWrap: "wrap",
          ...tagStyle,
        }}
      >
        {tag}
      </span>
      <span>{children}</span>
    </div>
  );
};

IconButton.propTypes = {
  iconStyle: PropTypes.object,
  tagStyle: PropTypes.object,
  tag: PropTypes.string,
  icon: PropTypes.string,
};

export default IconButton;
