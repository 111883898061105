import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import themeColor from "../../../styles/themeColor";
import { useWindowSize } from "../../../helpers/generalHelper";

const StepsConnector = (props) => {
  const {
    heightContainer = null,
    widthContainer = null,
    connectorHeight = 5,
  } = props;
  const [stepsConnectingBarMargin, setStepsConnectingBarMargin] = useState(10);
  const [stepsConnectingBarWidth, setStepsConnectingBarWidth] = useState(10);
  const windowSize = useWindowSize();

  const updateStepsConnectingBarMargin = (heightComponent, widthComponent) => {
    if (
      heightComponent &&
      heightComponent.current &&
      heightComponent.current.clientHeight
    ) {
      setStepsConnectingBarMargin(
        heightComponent.current.clientHeight / 2 - connectorHeight / 2
      );
    }

    if (
      widthComponent &&
      widthComponent.current &&
      widthComponent.current.clientWidth
    ) {
      const componentWidth = widthComponent.current.clientWidth;
      setStepsConnectingBarWidth(
        componentWidth > 500 ? componentWidth * 0.75 : componentWidth * 0.8
      );
    }
  };

  useEffect(() => {
    updateStepsConnectingBarMargin(heightContainer, widthContainer);
  }, [windowSize]);

  return (
    <div
      style={{
        height: connectorHeight,
        width: stepsConnectingBarWidth,
        border: "none",
        boxShadow: `0px 3px 8px ${themeColor.shadowGrey}`,
        position: "absolute",
        backgroundColor: themeColor.white,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: stepsConnectingBarMargin,
        zIndex: -1,
      }}
    />
  );
};

StepsConnector.propTypes = {
  heightContainer: PropTypes.object,
  connectorHeight: PropTypes.number,
};

export default StepsConnector;
