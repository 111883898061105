import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import themeColor from "../../../styles/themeColor";
import actions, { useActions } from "../../../redux/actions";

const buttonTextStyle = {
  fontSize: "0.9rem",
  letterSpacing: 0,
  color: themeColor.textColor,
  background: "transparent",
  // width: "20%",
  outline: "none",
};

const LogoutButton = withRouter((props) => {
  const { style, history, location } = props;
  const { t } = useTranslation();
  const accountActions = useActions(actions.AccountActions);
  const application = useActions(actions.ApplicationActions);

  return (
    <div style={{ ...style }}>
      <button
        style={{
          textAlign: "right",
          border: "none",
          outline: "none",
          ...buttonTextStyle,
        }}
        onClick={() => {
          accountActions.logout();
          application.reset();
          history.push({
            pathname: "/staff/login",
            state: { previousScreen: location.pathname },
          });
        }}
      >
        {t("authentication.logout")}
      </button>
    </div>
  );
});

export default LogoutButton;
