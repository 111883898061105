import React from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Col, Container, Row } from "react-bootstrap";
import StepOne from "./containers/StepOne";
import Header from "./components/general/header";
import { staffSteps, steps } from "./helpers/constants";
import StepTwo from "./containers/StepTwo";
import StepThree from "./containers/StepThree";
import StepFour from "./containers/StepFour";
import Login from "./containers/Login";
import StaffStepOne from "./containers/StepOne/staff";
import StaffLogin from "./containers/StaffLogin";

function PrivateRoute({ children, ...rest }) {
  const { token } = useSelector((state) => state.account);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )}
    />
  );
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Container fluid style={{ padding: 0 }}>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/staff/login">
              <StaffLogin />
            </Route>
            <div style={{ padding: 15 }}>
              <Row>
                <Header />
              </Row>
              <Row>
                <Col xs={12}>
                  <AnimatedSwitch>
                    <PrivateRoute exact path="/staff">
                      <StaffStepOne />
                    </PrivateRoute>
                    <PrivateRoute path="/staff/step1">
                      <StaffStepOne />
                    </PrivateRoute>
                    <Route exact path="/">
                      <StepOne />
                    </Route>
                    <Route path="/staff/step4">
                      <StepFour />
                    </Route>
                    <Route path="/step4">
                      <StepFour />
                    </Route>
                    <Route path="/staff/step3">
                      <StepThree />
                    </Route>
                    <Route path="/step3">
                      <StepThree />
                    </Route>
                    <Route path="/staff/step2">
                      <StepTwo />
                    </Route>
                    <Route path="/step2">
                      <StepTwo />
                    </Route>
                    <Route path="/step1">
                      <StepOne />
                    </Route>
                  </AnimatedSwitch>
                </Col>
              </Row>
            </div>
          </Switch>
        </Container>
      </BrowserRouter>
    </div>
  );
}

const AnimatedSwitch = withRouter(({ location, children }) => {
  const { token } = useSelector((state) => state.account);
  const appSteps =
    token && location.pathname.includes("/staff") ? staffSteps : steps;
  const currentScreen =
    appSteps.find((item) => item.pathname === location.pathname) || appSteps[0];
  const { state } = location;
  let previousScreen = state ? state.previousScreen : "/";
  previousScreen =
    appSteps.find((item) => item.pathname === previousScreen) || appSteps[0];
  const animationClassNames =
    currentScreen.step > previousScreen.step
      ? "slide-forward"
      : "slide-backward";
  return (
    <TransitionGroup
      childFactory={(child) =>
        React.cloneElement(child, {
          classNames: animationClassNames,
        })}
    >
      <CSSTransition
        key={location.key}
        classNames={animationClassNames}
        timeout={1000}
      >
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
});

export default App;
