import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const defaultValues = {
  applicantName: "",
  phone: "",
  email: "",
  vehicleRegistrationMark: "",
  vehicleLicenseExpireDate: "",
  vehicleLicenseCopy: null,
  vehicleLicenseCopy_url: "",
  isPhysicalDisability: false,
  disablePersonParkingPermit: null,
  disablePersonParkingPermit_url: "",
  type: "online",
  vehicletype: null,
  vehicleTypeEnum: "",
  campaign: null,
  carpark: null,
  remark: "",
  isAgreeConsent: false,
  isSubmitted: false,
  confirmEmail: "",
  vehicleLicenseCopyOCR: null,
  disablePersonParkingPermitOCR: null,
  applicationHardCopy: null,
  applicationHardCopy_url: "",
  no: null,
  isStep1Completed: false,
  isStep2Completed: false,
  isStep3Completed: false,
};

const initialState = Immutable({ ...defaultValues });

const applicationReducer = {
  application: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.APPLICATION_UPDATE:
        state = { ...state, ...action.data };
        return state;
      case ActionTypes.APPLICATION_RESET:
        state = { ...defaultValues };
        return state;
      case ActionTypes.APPLICATION_UPLOAD_ATTACHMENT:
        state = { ...state, ...action.data };
        return state;
      case ActionTypes.APPLICATION_SUBMIT:
        state = { ...state, ...action.data };
        return state;
      case ActionTypes.APPLICATION_OCR:
        state = { ...state, ...action.data };
        return state;
      default:
        return state;
    }
  },
};

export default applicationReducer;
