import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import QrReader from "react-qr-reader";
import actions, { useActions } from "../../redux/actions";
import Upload from "./upload";
import "./stepOne.css";
import { errorMessage } from "../../helpers/constants";
import Modal from "../../components/general/modal";
import Button from "../../components/general/button";
import qrCodeIcon from "../../assets/images/register/step1/qr_code.jpg";
import closeIcon from "../../assets/images/register/step2/cross.png";

const defaultValues = {
  carpark: {
    region: null,
    name: "-",
    name_zh: "-",
  },
  campaign: {
    parkingFrom: null,
    parkingTo: null,
    from: null,
    to: null,
    name: "-",
  },
  vehicletype: {
    name: "-",
    name_zh: "-",
  },
};

const InformationDisplay = (props) => {
  const { handleChange, handleUpload, disabled, setLoading } = props;
  const { t, i18n } = useTranslation();
  const application = useSelector((state) => state.application);
  const applicationActions = useActions(actions.ApplicationActions);
  const { token } = useSelector((state) => state.account);
  const [errMsg, setErrMsg] = useState("");
  const [info, setInfo] = useState(defaultValues);
  const [showQRScanner, setShowQRScanner] = useState(false);
  const handleGetInfo = debounce(1000, () => {
    setLoading(true);
    applicationActions.getApplicationInfo(
      application.no,
      token,
      (response) => {
        setInfo(response.responseDetail);
        setErrMsg("");
        setLoading(false);
      },
      (error) => {
        const errorMsg = errorMessage.find(
          (e) => e.code === error.responseCode
        );
        if (errorMsg) {
          setErrMsg(t(errorMsg.key));
        } else {
          setErrMsg(t("register.step1.staff.error"));
        }
        setInfo(defaultValues);
        setLoading(false);
      }
    );
  });

  const onScanError = (error) => {
    console.log(error);
    handleChange({ target: { value: "" } });
    setShowQRScanner(false);
    setErrMsg(t("register.step1.staff.error"));
  };

  const onScan = (data) => {
    if (data) {
      try {
        const url = new URL(data);
        const applicationNo = url.searchParams.get("app_no");
        handleChange({ target: { value: applicationNo || "" } });
        setShowQRScanner(false);
        if (!applicationNo) setErrMsg(t("register.step1.staff.error"));
      } catch (error) {
        console.log(error);
        onScanError(error);
      }
    }
  };

  useEffect(() => {
    if (application && application.no) handleGetInfo();
  }, [application.no]);

  return (
    <div>
      <Modal
        show={showQRScanner}
        onHide={() => setShowQRScanner(false)}
        size="xl"
      >
        <Modal.Body style={{ display: "flex", flexDirection: "row" }}>
          <QrReader
            delay={300}
            onError={onScanError}
            onScan={onScan}
            style={{
              width: "100%",
              maxWidth: "80vh",
              position: "relative",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <div
            style={{
              display: "block",
              padding: 10,
            }}
          >
            <button
              type="button"
              style={{
                background: "transparent",
                border: "none",
              }}
              onClick={() => {
                setShowQRScanner(false);
              }}
            >
              <img
                src={closeIcon}
                alt="close"
                style={{
                  objectFit: "contain",
                  width: 40,
                }}
              />
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="left dataInputBox">
        <div className="input-field">
          <span className="label">{t("register.step1.staff.appNumber")}</span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              type="text"
              name="no"
              onChange={handleChange}
              value={application.no}
              style={{ flex: 1 }}
            />
            <Button
              buttonType="icon"
              icon={qrCodeIcon}
              tagStyle={{ display: "none" }}
              iconStyle={{ objectFit: "contain", width: 30 }}
              style={{ padding: "0.4vh 1vw", marginLeft: 5 }}
              onClick={() => setShowQRScanner(true)}
            />
          </div>
          <span className="errMsg">{errMsg}</span>
        </div>
        <Upload handleUpload={handleUpload} disabled={disabled} />
      </div>
      <div className="right dataDisplayBox">
        <div className="info-field">
          <span className="label">{t("register.step1.staff.carpark")}</span>
          <span className="text">
            {i18n.language === "zh" ? info.carpark.name_zh : info.carpark.name}
          </span>
        </div>
        <div className="info-field">
          <span className="label">{t("register.step1.staff.region")}</span>
          <span className="text">
            {info.carpark.region
              ? t(`register.step1.${info.carpark.region}`)
              : "-"}
          </span>
        </div>
        <div className="info-field">
          <span className="label">{t("register.step1.staff.campaign")}</span>
          <span className="text">{info.campaign.name}</span>
        </div>
        <div className="info-field">
          <span className="label">
            {t("register.step1.staff.submissionDate")}
          </span>
          <span className="text">
            {info.campaign.from
              ? moment(info.campaign.from).format("DD/MM/YYYY")
              : ""}
            {" - "}
            {info.campaign.to
              ? moment(info.campaign.to).format("DD/MM/YYYY")
              : ""}
          </span>
        </div>
        <div className="info-field">
          <span className="label">
            {t("register.step1.staff.parkingPeriod")}
          </span>
          <span className="text">
            {info.campaign.parkingFrom
              ? moment(info.campaign.parkingFrom).format("DD/MM/YYYY")
              : ""}
            {" - "}
            {info.campaign.parkingTo
              ? moment(info.campaign.parkingTo).format("DD/MM/YYYY")
              : ""}
          </span>
        </div>
        <div className="info-field">
          <span className="label">{t("register.step1.staff.vehicleType")}</span>
          <span className="text">
            {i18n.language === "zh"
              ? info.vehicletype.name_zh
              : info.vehicletype.name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default InformationDisplay;
