import { useTranslation } from "react-i18next";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import themeColor from "../../styles/themeColor";
import Button from "../../components/general/button";
import upload_icon from "../../assets/images/register/step2/upload_icon.png";
import sampleImage from "../../assets/images/register/step2/sample-motor-vehicle-license.jpeg";
import { unacceptableImages } from "./constant";
import redCross from "../../assets/images/register/step2/unacceptableImages/red-cross.svg";

const UploadRemarks = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2vh 1vw",
      }}
    >
      <span
        style={{
          color: themeColor.greenTextColor,
          fontSize: "1.1rem",
          fontWeight: 500,
        }}
      >
        {t("register.step2.vehicleLicence")}
      </span>
      <span
        style={{
          color: themeColor.darkOptionColor,
          fontWeight: 500,
        }}
      >
        {t("register.step2.supportedFormat")}
      </span>
    </div>
  );
};

const UploadButton = (props) => {
  const { t } = useTranslation();
  const { handleUpload, disabled } = props;

  return (
    <div>
      <Button style={{ padding: "2vh 1vw" }}>
        <label>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <span
              style={{
                border: `1px solid ${themeColor.borderColor}`,
                borderRadius: 5,
                background: themeColor.backgroundColor,
                padding: "1vh 1vw",
                fontSize: "0.8rem",
              }}
            >
              {t("register.step2.upload")}
            </span>
          </div>
          <img
            src={upload_icon}
            alt="upload"
            style={{ width: "50%", aspectRatio: 1 }}
          />
          <input
            type="file"
            onChange={handleUpload}
            style={{ display: "none" }}
            disabled={disabled}
            onClick={(e) => (e.target.value = null)}
            accept="image/png,image/jpeg, image/jpg"
          />
        </label>
      </Button>
    </div>
  );
};

const SampleModal = ({ show, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} backdrop="static" onHide={handleClose} size="lg">
      <div
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <Button onClick={handleClose} style={{ alignSelf: "end" }}>
          {t("register.step2.close")}
        </Button>
        <p className="mb-2 text-danger text-center">
          {t("register.step2.unacceptableImage.example")}
        </p>

        <Row>
          {unacceptableImages.map((image, index) => (
            <Col lg={3} xs={6} md={4} key={index}>
              <div style={{ position: "relative" }} className="mb-2">
                <img
                  src={image.image}
                  alt={t(image.description)}
                  className="w-100 h-auto"
                />
                <img
                  src={redCross}
                  style={{
                    width: 40,
                    height: 40,
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                  }}
                  alt="cross"
                />
              </div>
              <p className="text-center">{t(image.description)}</p>
            </Col>
          ))}
        </Row>
      </div>
    </Modal>
  );
};

const Upload = (props) => {
  const { handleUpload, disabled, showSampleImage, setShowSampleImage } = props;
  const { t } = useTranslation();

  return (
    <Row>
      <Col sm={12} md={4} />
      <Col
        sm={12}
        md={4}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <UploadButton handleUpload={handleUpload} disabled={disabled} />
        <UploadRemarks />
      </Col>
      <Col sm={12} md={4} className="text-center mx-auto text-md-left mx-md-0">
        <div className="d-flex flex-column align-items-center align-items-md-start">
          <p style={{ color: "red", marginBottom: 0 }}>
            {t("register.step2.vehicleLicenseReminderTitle")}
          </p>
          <p style={{ color: "red" }}>
            {t("register.step2.vehicleLicenseReminder")}
          </p>
          <img
            src={sampleImage}
            style={{ height: 300, cursor: "pointer" }}
            onClick={() => setShowSampleImage(true)}
          />
        </div>
      </Col>
      <SampleModal
        show={showSampleImage}
        handleClose={() => setShowSampleImage(false)}
      />
    </Row>
  );
};

export default Upload;
