import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import React from "react";
import ContentCard from "../../components/general/contentCard";
import themeColor from "../../styles/themeColor";

const EngConsentRemark = () => {
  const { t } = useTranslation();
  const { campaign } = useSelector((state) => state.application);
  const {
    list,
    setting: { personalInformationCollectionStatement },
  } = useSelector((state) => state.campaign);
  const { guidanceNote } = list.find((c) => c._id === campaign) || {};

  return (
    <div>
      {t("register.step3.consentRemarks_1")}
      {personalInformationCollectionStatement &&
      personalInformationCollectionStatement.fileUrl ? (
        <a
          href={personalInformationCollectionStatement.fileUrl}
          target="_blank"
          style={{ color: themeColor.greenTextColor }}
        >
          {t("register.step3.consentRemarks_2")}
        </a>
      ) : (
        <span style={{ color: themeColor.greenTextColor }}>
          {t("register.step3.consentRemarks_2")}
        </span>
      )}
      {t("register.step3.consentRemarks_3")}
      {t("register.step3.consentRemarks_4")}
      {t("register.step3.consentRemarks_5")}
      {guidanceNote && guidanceNote.fileUrl ? (
        <a
          href={guidanceNote.fileUrl}
          target="_blank"
          style={{ color: themeColor.greenTextColor }}
        >
          {t("register.step3.consentRemarks_6")}
        </a>
      ) : (
        <span style={{ color: themeColor.greenTextColor }}>
          {t("register.step3.consentRemarks_6")}
        </span>
      )}
    </div>
  );
};

const ChiConsentRemark = () => {
  const { t } = useTranslation();
  const { campaign } = useSelector((state) => state.application);
  const {
    list,
    setting: { personalInformationCollectionStatement },
  } = useSelector((state) => state.campaign);
  const { guidanceNote } = list.find((c) => c._id === campaign) || {};

  return (
    <div>
      {t("register.step3.consentRemarks_1")}
      {personalInformationCollectionStatement &&
      personalInformationCollectionStatement.fileUrl ? (
        <a
          href={personalInformationCollectionStatement.fileUrl}
          target="_blank"
          style={{ color: themeColor.greenTextColor }}
        >
          {t("register.step3.consentRemarks_2")}
        </a>
      ) : (
        <span style={{ color: themeColor.greenTextColor }}>
          {t("register.step3.consentRemarks_2")}
        </span>
      )}
      {t("register.step3.consentRemarks_3")}
      {guidanceNote && guidanceNote.fileUrl ? (
        <a
          href={guidanceNote.fileUrl}
          target="_blank"
          style={{ color: themeColor.greenTextColor }}
        >
          {t("register.step3.consentRemarks_4")}
        </a>
      ) : (
        <span style={{ color: themeColor.greenTextColor }}>
          {t("register.step3.consentRemarks_4")}
        </span>
      )}
      {t("register.step3.consentRemarks_5")}
      {t("register.step3.consentRemarks_6")}
    </div>
  );
};

const InformationDisplay = (props) => {
  const { t, i18n } = useTranslation();

  const { location = "" } = props;
  const application = useSelector((state) => state.application);
  const vehicles = useSelector((state) => state.vehicle);
  const { token } = useSelector((state) => state.account);

  const vehicletype = vehicles.list.find(
    (vehicle) => vehicle._id === application.vehicletype
  );

  const isStaffSite = location.pathname.includes("/staff");

  return (
    <ContentCard leftTitle={t("register.step4.title")}>
      {token && isStaffSite && (
        <div className="info-field left">
          <span className="label">{t("register.step1.staff.form")}</span>
          <a
            className="text"
            href={decodeURIComponent(application.applicationHardCopy_url)}
            target="_blank"
            style={{ overflowWrap: "break-word" }}
          >
            {decodeURIComponent(application.applicationHardCopy_url).replace(
              process.env.REACT_APP_AWS_DOMAIN,
              ""
            )}
          </a>
        </div>
      )}
      <div className="sep-0" />
      <div className="info-field left">
        <span className="label">{t("register.step2.vehicleLicence")}</span>
        <a
          className="text"
          href={decodeURIComponent(application.vehicleLicenseCopy_url)}
          target="_blank"
          style={{ overflowWrap: "break-word" }}
        >
          {decodeURIComponent(application.vehicleLicenseCopy_url).replace(
            process.env.REACT_APP_AWS_DOMAIN,
            ""
          )}
        </a>
      </div>
      <div className="sep-0" />
      <div className="info-field left">
        <span className="label">
          {t("register.step3.vehicleRegistrationMark")}
        </span>
        <span className="text">{application.vehicleRegistrationMark}</span>
      </div>
      <div className="info-field right">
        <span className="label">
          {t("register.step3.vehicleLicenseExpireDate")}
        </span>
        <span className="text">
          {moment(application.vehicleLicenseExpireDate).format("DD-MM-YYYY")}
        </span>
      </div>
      <div className="info-field left">
        <span className="label">{t("register.step3.carType")}</span>
        <span className="text">
          {i18n.language === "zh" ? vehicletype.name_zh : vehicletype.name}
        </span>
      </div>
      <div className="sep-0" />
      <div className="info-field left">
        <span className="label">{t("register.step3.applicantName")}</span>
        <span className="text">{application.applicantName}</span>
      </div>
      <div className="info-field right step4-remark">
        <span className="label">{t("register.step3.remark")}</span>
        <span className="text">{application.remark}</span>
      </div>
      <div className="sep-0" />
      <div className="info-field left">
        <span className="label">{t("register.step3.phone")}</span>
        <span className="text">{application.phone}</span>
      </div>
      {/* {!(token && isStaffSite) && ( */}
      <div className="info-field right">
        <span className="label">{t("register.step3.email")}</span>
        <span className="text">{application.email}</span>
      </div>
      {/* )} */}
      <div className="sep-0" />
      <div className="info-field">
        <span className="label">{t("register.step3.physicalDisability")}</span>
        <span className="text">
          {application.isPhysicalDisability
            ? t("register.step3.yes")
            : t("register.step3.no")}
        </span>
      </div>
      <div className="info-field">
        <span className="label">
          {t("register.step3.parkingPermit")}
          {" / "}
          {t("register.step3.registrationCard")}
          {i18n.language === "zh" ? "：" : ":"}
        </span>
        {application.isPhysicalDisability ? (
          <a
            className="text"
            href={decodeURIComponent(
              application.disablePersonParkingPermit_url
            )}
            target="_blank"
            style={{ overflowWrap: "break-word" }}
          >
            {decodeURIComponent(
              application.disablePersonParkingPermit_url
            ).replace(process.env.REACT_APP_AWS_DOMAIN, "")}
          </a>
        ) : (
          <span className="text">{t("register.step4.na")}</span>
        )}
      </div>
      <div className="info-field">
        <span className="label">
          {i18n.language === "zh" ? <ChiConsentRemark /> : <EngConsentRemark />}
        </span>
        <span className="text">
          {application.isAgreeConsent
            ? t("register.step3.yes")
            : t("register.step3.no")}
        </span>
      </div>
    </ContentCard>
  );
};

export default InformationDisplay;
