import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import React from "react";
import themeColor from "../../styles/themeColor";
import close_icon from "../../assets/images/register/step2/cross.png";
import Button from "../../components/general/button";

const SuccessPage = withRouter((props) => {
  const { handleClose, history, location } = props;
  const { t } = useTranslation();
  const application = useSelector((state) => state.application);
  const { token } = useSelector((state) => state.account);

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", padding: 20 }}>
        <div style={{ width: "95%", padding: "20px 0 0 20px" }}>
          <span
            style={{
              color: themeColor.textColor,
              fontSize: "1.3rem",
            }}
          >
            {t("register.step2.confirmInfo")}
          </span>
          <div
            style={{
              display: "flex",
              marginTop: 50,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div style={{ width: "60%" }}>
                <span
                  style={{
                    color: themeColor.textColor,
                    fontSize: "1.3rem",
                    fontWeight: 500,
                  }}
                >
                  {t("register.step2.vehicleRegistrationMark")}
                </span>
              </div>
              <div style={{ width: "40%" }}>
                <span
                  style={{
                    color: themeColor.greenTextColor,
                    fontSize: "1.3rem",
                    fontWeight: 500,
                  }}
                >
                  {application.vehicleRegistrationMark}
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: 30,
                alignItems: "center",
              }}
            >
              <div style={{ width: "60%" }}>
                <span
                  style={{
                    color: themeColor.textColor,
                    fontSize: "1.3rem",
                    fontWeight: 500,
                  }}
                >
                  {t("register.step2.expirationDate")}
                </span>
              </div>
              <div style={{ width: "40%" }}>
                <span
                  style={{
                    color: themeColor.greenTextColor,
                    fontSize: "1.3rem",
                    fontWeight: 500,
                  }}
                >
                  {moment(application.vehicleLicenseExpireDate).format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: 30,
                alignItems: "center",
              }}
            >
              <div style={{ width: "60%" }}>
                <span
                  style={{
                    color: themeColor.textColor,
                    fontSize: "1.3rem",
                    fontWeight: 500,
                  }}
                >
                  {t("register.step2.vehicleType")}
                </span>
              </div>
              <div style={{ width: "40%" }}>
                <span
                  style={{
                    color: themeColor.greenTextColor,
                    fontSize: "1.3rem",
                    fontWeight: 500,
                  }}
                >
                  {application.vehicleTypeEnum}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ wdith: "5%", padding: 10 }}>
          <button
            type="button"
            style={{ background: "transparent", border: "none" }}
            onClick={() => {
              handleClose();
            }}
          >
            <img
              src={close_icon}
              alt="close"
              style={{
                objectFit: "contain",
                width: 40,
                float: "right",
              }}
            />
          </button>
        </div>
      </div>
      <div
        style={{
          marginTop: 20,
          marginBottom: 30,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => {
            handleClose();
            history.push({
              pathname:
                token && location.pathname.includes("/staff")
                  ? "/staff/step3"
                  : "/step3",
              state: { previousScreen: location.pathname },
            });
          }}
        >
          {t("register.next")}
        </Button>
      </div>
    </div>
  );
});

export default SuccessPage;
