import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Button from "../../components/general/button";
import ContentCard from "../../components/general/contentCard";
import themeColor from "../../styles/themeColor";
import Modal from "../../components/general/modal";
import actions, { useActions } from "../../redux/actions";
import FailPage from "./failPage";
import SuccessPage from "./successPage";
import Upload from "./upload";
import SpinnerOverlay from "../../components/general/spinnerOverlay";
import { attachmentType, errorMessage } from "../../helpers/constants";

const ONE_MEGABYTE = 1048576;

const Dialog = (props) => {
  const { handleClose, show, valid } = props;

  return (
    <Modal show={show} backdrop="static" onHide={handleClose}>
      {valid ? (
        <SuccessPage handleClose={handleClose} />
      ) : (
        <FailPage handleClose={handleClose} />
      )}
    </Modal>
  );
};

const blueButtonStyle = {
  borderColor: themeColor.textColor,
  color: themeColor.textColor,
  background: themeColor.lightOptionColor,
};

const greyButtonStyle = {
  borderColor: themeColor.grey,
  color: themeColor.grey,
  background: themeColor.white,
};

const StepTwo = withRouter((props) => {
  const { t } = useTranslation();
  const { history, location } = props;
  const application = useSelector((state) => state.application);
  const applicationActions = useActions(actions.ApplicationActions);
  const { token } = useSelector((state) => state.account);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [showSampleImage, setShowSampleImage] = useState(true);
  const isStaffSite = location.pathname.includes("/staff");
  const handleUpload = (e) => {
    if (e.target.files[0].size > ONE_MEGABYTE * 8) {
      alert(t("register.overMaxImageLimit"));
      return;
    }
    if (
      !["image/jpeg", "image/png", "image/jpg"].includes(e.target.files[0].type)
    ) {
      alert(t("register.invalidImageFormat"));
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    applicationActions.uploadAttachment(
      "vehicleLicenseCopy",
      formData,
      attachmentType.VEHICLE_LICENSE,
      token,
      (response) => {
        const data = {
          attachment: response.vehicleLicenseCopy,
          type: "vehicleLicense",
        };
        applicationActions.ocr(
          data,
          token,
          (response) => {
            setShowModal(true);
            setLoading(false);
            setValid(response.responseDetail.valid);
          },
          () => {
            setShowModal(true);
            setLoading(false);
            setValid(false);
          }
        );
      },
      (error) => {
        const errorMsg = errorMessage.find(
          (e) => e.code === error.responseCode
        );
        if (errorMsg) {
          alert(t(errorMsg.key));
        }
        setShowModal(false);
        setLoading(false);
      }
    );
  };
  const handleClose = () => {
    setShowModal(false);
  };

  if (!application.isStep1Completed) {
    if (token && isStaffSite) return <Redirect to="/staff/step1" />;
    return <Redirect to="/step1" />;
  }

  useEffect(() => {
    if (application.vehicleLicenseCopy) {
      applicationActions.update({ isStep2Completed: true });
    } else {
      applicationActions.update({ isStep2Completed: false });
    }
  }, [application.vehicleLicenseCopy]);

  return (
    <Container fluid>
      <Row>
        <ContentCard leftTitle={t("register.step2.title")}>
          <Dialog handleClose={handleClose} show={showModal} valid={valid} />
          <Upload
            handleUpload={handleUpload}
            disabled={loading}
            showSampleImage={showSampleImage}
            setShowSampleImage={setShowSampleImage}
          />
        </ContentCard>
      </Row>
      <Row
        style={{
          padding: "0px 15px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <Button
          onClick={() => {
            history.push({
              pathname: token && isStaffSite ? "/staff/step1" : "/step1",
              state: { previousScreen: location.pathname },
            });
          }}
          disabled={loading}
        >
          {t("register.back")}
        </Button>
        <Button
          onClick={() => {
            if (application.isStep2Completed) {
              history.push({
                pathname: token && isStaffSite ? "/staff/step3" : "/step3",
                state: { previousScreen: location.pathname },
              });
            }
          }}
          style={
            application.isStep2Completed && !loading
              ? blueButtonStyle
              : greyButtonStyle
          }
          disabled={loading}
        >
          {t("register.next")}
        </Button>
      </Row>
      <SpinnerOverlay loading={loading} />
    </Container>
  );
});

export default StepTwo;
