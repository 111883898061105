import Immutable from "seamless-immutable";
import moment from "moment";
import ActionTypes from "../actions/types";

const initialState = Immutable({
  list: [],
  lastUpdate: "",
  setting: {
    personalInformationCollectionStatement: {},
    guidanceNote: {},
  },
});

const campaignReducer = {
  campaign: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.CAMPAIGN_GET_LIST:
        return {
          ...state,
          list: action.data,
          lastUpdate: moment().utcOffset(8).format("YYYY-MM-DD HH:mm:ss"),
          setting: action.setting,
          withinPaymentPeriod: action.withinPaymentPeriod,
        };
      default:
        return state;
    }
  },
};

export default campaignReducer;
