import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";
import AnimatedIcons from "../../components/general/animatedIcons";
import themeColor from "../../styles/themeColor";

const SuccessPage = withRouter((props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          display: "flex",
          padding: 20,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <AnimatedIcons.SuccessIcon />
        <span
          style={{
            color: themeColor.textColor,
            fontSize: "1.2rem",
            fontWeight: 500,
          }}
        >
          {t("register.step1.staff.uploadSuccess")}
        </span>
      </div>
    </div>
  );
});

export default SuccessPage;
