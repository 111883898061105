import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const initialState = Immutable({
  loggedIn: false,
  token: null,
});

const accountReducer = {
  account: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.ACCOUNT_LOGIN:
        state = { ...state, token: action.data, loggedIn: true };
        return state;
      case ActionTypes.ACCOUNT_LOGOUT:
        state = { ...state, token: null, loggedIn: false };
        return state;
      default:
        return state;
    }
  },
};

export default accountReducer;
