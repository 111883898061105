import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import themeColor from "../../../styles/themeColor";

const ContentCardTitle = withRouter((props) => {
  const { leftTitle = "", rightTitle = "" } = props;

  return (
    <Container
      fluid
      style={{
        border: "solid",
        borderWidth: 1,
        borderColor: themeColor.borderColor,
        borderRadius: 10,
        backgroundColor: themeColor.darkOptionColor,
      }}
    >
      <Row>
        <Col xs={12} md={6}>
          <span
            style={{
              margin: 20,
              fontSize: "1.15rem",
              fontWeight: "600",
              color: themeColor.textColor,
              textAlign: "left",
              float: "left",
            }}
          >
            {leftTitle}
          </span>
        </Col>
        <Col xs={12} md={6}>
          <span
            style={{
              margin: 20,
              fontSize: "1.15rem",
              fontWeight: "400",
              color: themeColor.textColor,
              textAlign: "right",
              float: "right",
            }}
          >
            {rightTitle}
          </span>
        </Col>
      </Row>
    </Container>
  );
});

ContentCardTitle.propTypes = {
  leftTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ContentCardTitle;
