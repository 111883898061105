import baseAPIHelper from "./baseAPIHelper";

function login(staffNumber, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/auth/login`;
  const data = {
    username: staffNumber,
    password: "12345678",
  };
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

const accountAPIHelper = {
  login,
};

export default accountAPIHelper;
