import React from "react";
import { useSelector } from "react-redux";
import LoginButton from "./loginButton";
import LogoutButton from "./logoutButton";

const AuthButton = (props) => {
  const { style } = props;
  const account = useSelector((state) => state.account);

  return <div style={{ ...style }}>{account.loggedIn && <LogoutButton />}</div>;
};

export default AuthButton;
