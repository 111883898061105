import ActionTypes from "./types";
import accountAPIHelper from "../../helpers/api/accountAPIHelper";

function login(staffNumber, successCallback = null, failCallback = null) {
  return (dispatch) => {
    accountAPIHelper.login(
      staffNumber,
      (response) => {
        dispatch({
          type: ActionTypes.ACCOUNT_LOGIN,
          data: response.responseDetail.token,
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

function logout() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ACCOUNT_LOGOUT,
    });
  };
}

const AccountActions = { login, logout };
export default AccountActions;
