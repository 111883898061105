import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import CardView from "../cardView";
import themeColor from "../../../styles/themeColor";
import ContentCardTitle from "./contentCardTitle";

const ContentCard = withRouter((props) => {
  const { leftTitle = "", rightTitle = "", children } = props;

  return (
    <Container fluid style={{ display: "flex" }}>
      <CardView
        style={{
          marginTop: 20,
          marginBottom: 20,
          flex: 1,
          border: "solid",
          borderWidth: 1,
          borderColor: themeColor.borderColor,
          borderRadius: 10,
          backgroundColor: themeColor.backgroundColor,
          // minHeight: "60vh",
        }}
      >
        <ContentCardTitle leftTitle={leftTitle} rightTitle={rightTitle} />

        <Container
          fluid
          style={{
            padding: 20,
          }}
        >
          {children}
        </Container>
      </CardView>
    </Container>
  );
});

ContentCard.propTypes = {
  leftTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ContentCard;
