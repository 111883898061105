import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Button from "../../components/general/button";
import ContentCard from "../../components/general/contentCard";
import actions, { useActions } from "../../redux/actions";
import Modal from "../../components/general/modal";
import themeColor from "../../styles/themeColor";
import SuccessPage from "./successPage";
import FailPage from "./failPage";
import "./stepOne.css";
import InformationDisplay from "./informationDisplay";
import SpinnerOverlay from "../../components/general/spinnerOverlay";

const ONE_MEGABYTE = 1048576;

const Dialog = (props) => {
  const { show, success } = props;

  return (
    <Modal show={show} backdrop="static">
      {success ? <SuccessPage /> : <FailPage />}
    </Modal>
  );
};

const blueButtonStyle = {
  borderColor: themeColor.textColor,
  color: themeColor.textColor,
  background: themeColor.lightOptionColor,
};

const greyButtonStyle = {
  borderColor: themeColor.grey,
  color: themeColor.grey,
  background: themeColor.white,
};

const StaffStepOne = withRouter((props) => {
  const { history, location } = props;
  const { t } = useTranslation();
  const application = useSelector((state) => state.application);
  const applicationActions = useActions(actions.ApplicationActions);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state) => state.account);
  const [modal, setModal] = useState({ show: false, success: false });

  const handleUpload = (e) => {
    if (e.target.files[0].size > ONE_MEGABYTE * 8) {
      alert(t("register.overMaxImageLimit"));
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      applicationActions.uploadAttachment(
        "applicationHardCopy",
        formData,
        "",
        token,
        () => {
          setModal({ show: true, success: true });
          setTimeout(() => {
            setModal({ show: false, success: true });
          }, 2000);
          setLoading(false);
        },
        () => {
          setModal({ show: true, success: false });
          setTimeout(() => {
            setModal({ show: false, success: false });
          }, 2000);
          setLoading(false);
        }
      );
    }
  };

  const handleChange = (e) => {
    applicationActions.update({ no: e.target.value });
  };

  useEffect(() => {
    if (
      application.applicationHardCopy &&
      application.no &&
      application.campaign
    ) {
      applicationActions.update({ isStep1Completed: true });
    } else {
      applicationActions.update({ isStep1Completed: false });
    }
  }, [application.no, application.campaign, application.applicationHardCopy]);

  return (
    <Container fluid>
      <Row>
        <ContentCard
          leftTitle={t("register.step1.staff.title")}
          style={{ padding: 20 }}
        >
          <Dialog show={modal.show} success={modal.success} />
          <InformationDisplay
            handleChange={handleChange}
            handleUpload={handleUpload}
            disabled={loading}
            setLoading={setLoading}
          />
        </ContentCard>
      </Row>
      <Row
        style={{
          padding: "0px 15px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <div />
        <Button
          onClick={() => {
            if (application.isStep1Completed) {
              history.push({
                pathname:
                  token && location.pathname.includes("/staff")
                    ? "/staff/step2"
                    : "/step2",
                state: { previousScreen: location.pathname },
              });
            }
          }}
          style={
            !loading && application.isStep1Completed
              ? blueButtonStyle
              : greyButtonStyle
          }
          disabled={loading}
        >
          {t("register.next")}
        </Button>
      </Row>
      <SpinnerOverlay loading={loading} />
    </Container>
  );
});

export default StaffStepOne;
