import { useTranslation } from "react-i18next";
import React from "react";
import themeColor from "../../styles/themeColor";
import Button from "../../components/general/button";
import upload_icon from "../../assets/images/register/step2/upload_icon.png";

const UploadRemarks = (props) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2vh 1vw",
      }}
    >
      <span
        style={{
          color: themeColor.greenTextColor,
          fontSize: "1.1rem",
          fontWeight: 500,
        }}
      >
        {t("register.step1.staff.form")}
      </span>
    </div>
  );
};

const UploadButton = (props) => {
  const { t } = useTranslation();
  const { handleUpload, disabled } = props;

  return (
    <div>
      <Button style={{ padding: "2vh 1vw" }}>
        <label>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <span
              style={{
                border: `1px solid ${themeColor.borderColor}`,
                borderRadius: 5,
                background: themeColor.backgroundColor,
                padding: "1vh 1vw",
                fontSize: "0.8rem",
              }}
            >
              {t("register.step2.upload")}
            </span>
          </div>
          <img
            src={upload_icon}
            alt="upload"
            style={{ width: "50%", aspectRatio: 1 }}
          />
          <input
            type="file"
            onChange={handleUpload}
            style={{ display: "none" }}
            disabled={disabled}
            onClick={(e) => (e.target.value = null)}
          />
        </label>
      </Button>
    </div>
  );
};

const Upload = (props) => {
  const { handleUpload, disabled } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <UploadButton handleUpload={handleUpload} disabled={disabled} />
      <UploadRemarks />
    </div>
  );
};

export default Upload;
