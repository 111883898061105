import { combineReducers } from "redux";
import accountReducer from "./account";
import applicationReducer from "./application";
import announcementReducer from "./announcement";
import campaignReducer from "./campaign";
import vehicleReducer from "./vehicle";

export default combineReducers(
  Object.assign(
    accountReducer,
    applicationReducer,
    announcementReducer,
    campaignReducer,
    vehicleReducer
  )
);
