import React from "react";
import themeColor from "../../styles/themeColor";

const CardView = (props) => {
  const { style, children } = props;
  return (
    <div
      style={{
        width: "min-content",
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: `0px 3px 8px ${themeColor.shadowGrey}`,
        ...style,
      }}
    >
      <div style={{ flex: 1, overflow: "hidden" }}>{children}</div>
    </div>
  );
};

export default CardView;
