const themeColor = {
  white: "#FFFFFF",
  black: "#000000",
  backgroundColor: "#F9FAFB",
  textColor: "#597C89",
  lightOptionColor: "#DFE3E5",
  darkOptionColor: "#aec3cb",
  borderColor: "#7F99A4",
  shadowGrey: "#00000029",
  greenTextColor: "#00B289",
  redTextColor: "#DC4433",
  grey: "#C6C2C2",
  inputFieldBorderColor: "#707070",
};

export default themeColor;
