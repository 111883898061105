import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import themeColor from "../../../styles/themeColor";
import StepsConnector from "./stepsConnector";

const StepItem = withRouter((props) => {
  const {
    progressBarContainer = null,
    stepItemContainerStyle = {},
    stepNumberStyle = {},
    stepLabelStyle = {},
    activeIndex,
    index,
    label,
    history,
    location,
  } = props;
  const stepNumberContainer = useRef(null);
  const application = useSelector((state) => state.application);
  const { token } = useSelector((state) => state.account);
  const backgroundColor =
    activeIndex > index + 1 || application.isSubmitted
      ? themeColor.greenTextColor
      : activeIndex === index + 1
      ? themeColor.darkOptionColor
      : themeColor.lightOptionColor;
  const isStaffSite = location.pathname.includes("/staff");

  const handleChangePage = () => {
    for (let i = 1; i < index + 1; i += 1) {
      if (i === 1 && !application.isStep1Completed) {
        history.push({
          pathname: token && isStaffSite ? "/staff/step1" : "/step1",
          state: { previousScreen: location.pathname },
        });
        return;
      }
      if (i === 2 && !application.isStep2Completed) {
        history.push({
          pathname: token && isStaffSite ? "/staff/step2" : "/step2",
          state: { previousScreen: location.pathname },
        });
        return;
      }
      if (i === 3 && !application.isStep3Completed) {
        history.push({
          pathname: token && isStaffSite ? "/staff/step3" : "/step3",
          state: { previousScreen: location.pathname },
        });
        return;
      }
    }

    if (!application.isSubmitted) {
      history.push({
        pathname:
          token && isStaffSite
            ? `/staff/step${index + 1}`
            : `/step${index + 1}`,
        state: { previousScreen: location.pathname },
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        flexWrap: "wrap",
        ...stepItemContainerStyle,
      }}
    >
      <div ref={stepNumberContainer}>
        {index === 0 && (
          <StepsConnector
            heightContainer={stepNumberContainer}
            widthContainer={progressBarContainer}
            connectorHeight={5}
          />
        )}
        <button
          style={{
            border: "solid",
            borderColor: themeColor.borderColor,
            borderWidth: activeIndex === index + 1 ? 2 : 1,
            borderRadius: 10,
            outline: "none",
            fontSize: "1.1rem",
            fontWeight: activeIndex === index + 1 ? "600" : "400",
            color:
              activeIndex > index + 1 || application.isSubmitted
                ? themeColor.white
                : themeColor.textColor,
            backgroundColor,
            boxShadow: `0px 3px 8px ${themeColor.shadowGrey}`,
            opacity: 1,
            padding: "0.38rem 0.85rem",
            textAlign: "center",
            zIndex: 0,
            ...stepNumberStyle,
          }}
          onClick={handleChangePage}
        >
          {activeIndex > index + 1 || application.isSubmitted ? "✓" : index + 1}
        </button>
      </div>
      <span
        style={{
          marginTop: 20,
          fontSize: "1rem",
          fontWeight: activeIndex === index + 1 ? "600" : "400",
          color:
            activeIndex > index + 1 || application.isSubmitted
              ? themeColor.greenTextColor
              : themeColor.textColor,
          maxWidth: "15vw",
          textAlign: "center",
          wordWrap: "break-word",
          hyphens: "auto",
          ...stepLabelStyle,
        }}
      >
        {label}
      </span>
    </div>
  );
});

StepItem.propTypes = {
  progressBarContainer: PropTypes.object,
  stepItemContainerStyle: PropTypes.object,
  stepNumberStyle: PropTypes.object,
  stepLabelStyle: PropTypes.object,
  activeIndex: PropTypes.number,
  index: PropTypes.number,
  label: PropTypes.string,
};

export default StepItem;
