import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React from "react";
import themeColor from "../../styles/themeColor";
import cross_icon from "../../assets/images/register/step2/cancel-80.png";
import Button from "../../components/general/button";

const FailPage = withRouter((props) => {
  const { handleClose, history, location } = props;
  const { t } = useTranslation();
  const application = useSelector((state) => state.application);
  const { token } = useSelector((state) => state.account);

  return (
    <div>
      <div style={{ padding: 40 }}>
        <span
          style={{
            color: themeColor.textColor,
            fontSize: "1.3rem",
          }}
        >
          {t("register.step2.failToRecognise")}
        </span>
        <div
          style={{ display: "flex", marginTop: 20, flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div style={{ width: "60%" }}>
              <span
                style={{
                  color: application.vehicleRegistrationMark
                    ? themeColor.textColor
                    : themeColor.redTextColor,
                  fontSize: "1.3rem",
                  fontWeight: 500,
                }}
              >
                {t("register.step2.vehicleRegistrationMark")}
              </span>
            </div>
            <div style={{ width: "40%" }}>
              {application.vehicleRegistrationMark ? (
                <span
                  style={{
                    color: themeColor.greenTextColor,
                    fontSize: "1.3rem",
                    fontWeight: 500,
                  }}
                >
                  {application.vehicleRegistrationMark}
                </span>
              ) : (
                <img src={cross_icon} alt="error" style={{ width: 40 }} />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 30,
              alignItems: "center",
            }}
          >
            <div style={{ width: "60%" }}>
              <span
                style={{
                  color: application.vehicleLicenseExpireDate
                    ? themeColor.textColor
                    : themeColor.redTextColor,
                  fontSize: "1.3rem",
                  fontWeight: 500,
                }}
              >
                {t("register.step2.expirationDate")}
              </span>
            </div>
            <div style={{ width: "40%" }}>
              {application.vehicleLicenseExpireDate ? (
                <span
                  style={{
                    color: themeColor.greenTextColor,
                    fontSize: "1.3rem",
                    fontWeight: 500,
                  }}
                >
                  {application.vehicleLicenseExpireDate}
                </span>
              ) : (
                <img src={cross_icon} alt="error" style={{ width: 40 }} />
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 30,
              alignItems: "center",
            }}
          >
            <div style={{ width: "60%" }}>
              <span
                style={{
                  color: application.vehicleTypeEnum
                    ? themeColor.textColor
                    : themeColor.redTextColor,
                  fontSize: "1.3rem",
                  fontWeight: 500,
                }}
              >
                {t("register.step2.vehicleType")}
              </span>
            </div>
            <div style={{ width: "40%" }}>
              {application.vehicleTypeEnum ? (
                <span
                  style={{
                    color: themeColor.greenTextColor,
                    fontSize: "1.3rem",
                    fontWeight: 500,
                  }}
                >
                  {application.vehicleTypeEnum}
                </span>
              ) : (
                <img src={cross_icon} alt="error" style={{ width: 40 }} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 20,
          marginBottom: 30,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => {
            handleClose();
          }}
          style={{ padding: "2vh 0.5vw 2vh 0.5vw", width: "40%" }}
        >
          {t("register.step2.retry")}
        </Button>
        <Button
          onClick={() => {
            handleClose();
            history.push({
              pathname:
                token && location.pathname.includes("/staff")
                  ? "/staff/step3"
                  : "/step3",
              state: { previousScreen: location.pathname },
            });
          }}
          style={{
            marginLeft: 20,
            width: "40%",
            padding: "2vh 0.5vw 2vh 0.5vw",
          }}
        >
          {t("register.next")}
          <br />
          <span style={{ fontSize: "0.8rem" }}>
            {t("register.step2.amendInfo")}
          </span>
        </Button>
      </div>
      <div
        style={{
          marginTop: 20,
          marginBottom: 30,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h5 style={{ color: themeColor.textColor }}>
          {t("register.step2.importantNoteTitle")}
        </h5>
        <p style={{ maxWidth: "90%", color: themeColor.textColor }}>
          {t("register.step2.importantNoteContent")}
        </p>
      </div>
    </div>
  );
});

export default FailPage;
