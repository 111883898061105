import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import ContentCard from "../../components/general/contentCard";
import themeColor from "../../styles/themeColor";
import AnimatedIcons from "../../components/general/animatedIcons";
import { errorMessage } from "../../helpers/constants";

const FailPage = (props) => {
  const { t, i18n } = useTranslation();
  const { errorCode } = props;
  const [errorMsg, setErrorMsg] = useState({ key: "register.step4.tryAgain" });
  useEffect(() => {
    setErrorMsg(
      errorMessage.find((error) => error.code === errorCode) || {
        key: "register.step4.tryAgain",
      }
    );
  }, [errorCode]);

  return (
    <ContentCard leftTitle={t("register.step4.failTitle")}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          color: themeColor.textColor,
          fontWeight: 500,
          fontSize: "1.2rem",
        }}
      >
        <AnimatedIcons.FailIcon />
        <span style={{ marginBottom: 20 }}>
          {t("register.step4.failTitle")}
        </span>
        {errorCode ? (
          <span style={{ textAlign: "center" }}>{t(errorMsg.key)}</span>
        ) : (
          <span style={{ textAlign: "center" }}>
            {t("register.step4.tryAgain")}
          </span>
        )}
      </div>
    </ContentCard>
  );
};

export default FailPage;
