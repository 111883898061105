import React from "react";
import AnimatedIcons from "../../components/general/animatedIcons";
import themeColor from "../../styles/themeColor";
import Modal from "../../components/general/modal";

const GeneralWarningModal = (props) => {
  const { show, handleClose, content } = props;

  return (
    <Modal show={show} onHide={handleClose}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 20,
          alignItems: "center",
          width: "100%",
        }}
      >
        <AnimatedIcons.WarnIcon />
        <span
          style={{
            color: themeColor.textColor,
            fontSize: "1.2rem",
            fontWeight: 500,
          }}
        >
          {content}
        </span>
      </div>
    </Modal>
  );
};

export default GeneralWarningModal;
