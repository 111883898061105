import React, { useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Button from "../../components/general/button";
import "./stepFour.css";
import actions, { useActions } from "../../redux/actions";
import InformationDisplay from "./informationDisplay";
import SuccessPage from "./successPage";
import FailPage from "./failPage";
import SpinnerOverlay from "../../components/general/spinnerOverlay";

const renderPageContent = (content, errorCode = null, location) => {
  switch (content) {
    case "display":
      return <InformationDisplay location={location} />;
    case "success":
      return <SuccessPage />;
    case "fail":
      return <FailPage errorCode={errorCode} />;
    default:
      return <InformationDisplay location={location} />;
  }
};

const StepFour = withRouter((props) => {
  const { history, location } = props;
  const { t, i18n } = useTranslation();
  const [content, setContent] = useState("display");
  const applicationActions = useActions(actions.ApplicationActions);
  const application = useSelector((state) => state.application);
  const { token } = useSelector((state) => state.account);
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  const isStaffSite = location.pathname.includes("/staff");

  const handleSubmit = () => {
    setLoading(true);
    const data = {
      applicantName: application.applicantName,
      phone: application.phone,
      email: application.email,
      vehicleRegistrationMark: application.vehicleRegistrationMark,
      vehicleLicenseExpireDate: application.vehicleLicenseExpireDate,
      vehicleLicenseCopy: application.vehicleLicenseCopy,
      isPhysicalDisability: application.isPhysicalDisability,
      disablePersonParkingPermit: application.disablePersonParkingPermit,
      type: "online",
      vehicletype: application.vehicletype,
      campaign: application.campaign,
      remark: application.remark,
      vehicleLicenseCopyOCR: application.vehicleLicenseCopyOCR,
      disablePersonParkingPermitOCR: application.disablePersonParkingPermitOCR,
    };
    if (token && isStaffSite) {
      data.no = application.no;
      data.applicationHardCopy = application.applicationHardCopy;
    }
    applicationActions.submit(
      data,
      token,
      () => {
        setContent("success");
        setLoading(false);
        setErrorCode(null);
      },
      (error) => {
        setContent("fail");
        setLoading(false);
        setErrorCode(error.responseCode);
      }
    );
  };
  const handleFinish = () => {
    applicationActions.reset();
    history.push({
      pathname: token && isStaffSite ? "/staff/step1" : "/step1",
      state: { previousScreen: "/" },
    });
  };

  if (!application.isStep3Completed) {
    if (token && isStaffSite) return <Redirect to="/staff/step3" />;
    return <Redirect to="/step3" />;
  }

  if (!application.isStep2Completed) {
    if (token && isStaffSite) return <Redirect to="/staff/step2" />;
    return <Redirect to="/step2" />;
  }

  if (!application.isStep1Completed) {
    if (token && isStaffSite) return <Redirect to="/staff/step1" />;
    return <Redirect to="/step1" />;
  }

  return (
    <Container fluid>
      <Row>{renderPageContent(content, errorCode, location)}</Row>
      <Row
        style={{
          padding: "0px 15px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        {content === "display" || content === "fail" ? (
          <Button
            onClick={() => {
              if (content === "display") {
                history.push({
                  pathname: token && isStaffSite ? "/staff/step3" : "/step3",
                  state: { previousScreen: location.pathname },
                });
              } else {
                setContent("display");
              }
            }}
            disabled={loading}
          >
            {t("register.back")}
          </Button>
        ) : (
          <div />
        )}

        {content === "display" ? (
          <Button onClick={handleSubmit} disabled={application.isSubmitted}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {t("register.confirm")}
            </div>
          </Button>
        ) : (
          <Button onClick={handleFinish} disabled={loading}>
            {t("register.finish")}
          </Button>
        )}
      </Row>
      <SpinnerOverlay loading={loading} />
    </Container>
  );
});

export default StepFour;
