import React from "react";
import i18next from "i18next";
import themeColor from "../../styles/themeColor";

const buttonTextStyle = {
  fontSize: "0.9rem",
  letterSpacing: 0,
  color: themeColor.textColor,
  background: "transparent",
  // width: "20%",
  outline: "none",
};

const LangSwitch = (props) => {
  const { style } = props;
  return (
    <div style={{ ...style }}>
      <button
        style={{
          textAlign: "right",
          border: "none",
          outline: "none",
          ...buttonTextStyle,
        }}
        onClick={() => i18next.changeLanguage("zh")}
      >
        繁
      </button>
      <span
        style={{
          width: "auto",
          ...buttonTextStyle,
        }}
      >
        &nbsp;|&nbsp;
      </span>
      <button
        style={{
          textAlign: "left",
          border: "none",
          outline: "none",
          ...buttonTextStyle,
        }}
        onClick={() => i18next.changeLanguage("en")}
      >
        Eng
      </button>
    </div>
  );
};

export default LangSwitch;
