import baseAPIHelper from "./baseAPIHelper";

function uploadAttachment(token, data, type, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/attachment/upload`;
  if (type) {
    const urlWithType = new URL(url);
    urlWithType.searchParams.set("type", type);
    url = urlWithType.toString();
  }
  if (token) {
    const urlWithToken = new URL(url);
    urlWithToken.searchParams.set("access_token", token);
    url = urlWithToken.toString();
  }
  baseAPIHelper(
    "POST",
    url,
    data,
    successCallback,
    failCallback,
    new Headers({}),
    true
  );
}

function submit(token, data, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/application`;
  if (token) {
    const urlWithToken = new URL(url);
    urlWithToken.searchParams.set("access_token", token);
    url = urlWithToken.toString();
  }
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

function ocr(token, data, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/ocr`;
  if (token) {
    const urlWithToken = new URL(url);
    urlWithToken.searchParams.set("access_token", token);
    url = urlWithToken.toString();
  }
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

function getApplicationInfo(token, no, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/applicationpool/${no}`;
  if (token) {
    const urlWithToken = new URL(url);
    urlWithToken.searchParams.set("access_token", token);
    url = urlWithToken.toString();
  }
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

function getRegisteredUserInfo(token, phone, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/applicationpool/phone/${phone}`;
  if (token) {
    const urlWithToken = new URL(url);
    urlWithToken.searchParams.set("access_token", token);
    url = urlWithToken.toString();
  }
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

const applicationAPIHelper = {
  uploadAttachment,
  submit,
  ocr,
  getApplicationInfo,
  getRegisteredUserInfo,
};

export default applicationAPIHelper;
