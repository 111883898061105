import Immutable from "seamless-immutable";
import moment from "moment";
import ActionTypes from "../actions/types";

const initialState = Immutable({
  list: [],
  lastUpdate: "",
});

const announcementReducer = {
  announcement: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.ANNOUNCEMENT_GET_LIST:
        state = {
          ...state,
          list: action.data,
          lastUpdate: moment().utcOffset(8).format("YYYY-MM-DD HH:mm:ss"),
        };
        return state;
      default:
        return state;
    }
  },
};

export default announcementReducer;
