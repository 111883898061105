export default {
  home: {
    title: "網上抽籤系統",
    register: "登記",
    login: "職員登入",
  },
  authentication: {
    staffLogin: "職員登入",
    login: "登入",
    logout: "登出",
    staffNumber: "職員編號",
    notStaff: "不是職員",
    fail: "登入失敗",
    close: "關閉",
    unauthorized: "禁止進入：此IP地址已被拒絕。",
  },
  header: {
    companyName: "運輸署",
    title: "運輸署多層停車場 - 透過互聯網提交季租泊車服務申請",
    firstHalf: "，及適用於成功中籤申請人的",
    payLink: "付款連結",
    secondHalf: "(詳情參閱”申請季租泊車服務說明書”)",
  },
  register: {
    step1: {
      title: "選擇停車場",
      campaignTitle: "停車場季租泊車服務",
      available: " - 可供申請",
      coming: " - 將接受申請",
      HK: "香港",
      KLN: "九龍",
      NT: "新界",
      from: "由",
      to: "至",
      submissionDate: "交表日期: ",
      noResult: "沒有結果",
      staff: {
        title: "上載申請表",
        form: "申請表",
        uploadFail: "上載失敗",
        retry: "重新上載申請表",
        uploadSuccess: "上載成功",
        appNumber: "申請編號",
        notFound: "未能找到相關紀錄。",
        carparkDetail: "停車場資料",
        error: "錯誤! 請重試。",
        carpark: "停車場",
        region: "區域",
        campaign: "活動",
        parkingPeriod: "泊車時段",
        submissionDate: "交表日期",
        vehicleType: "車輛種類",
      },
    },
    step2: {
      title: "上載文件",
      upload: "上載",
      vehicleLicence: "車輛牌照（行車證）",
      confirmInfo: "請確認下列資料為正確：",
      vehicleRegistrationMark: "車輛登記號碼：",
      expirationDate: "牌照屆滿日期：",
      vehicleType: "車輛種類：",
      failToRecognise: "下列資料未能成功辨認：",
      retry: "重新上載文件",
      amendInfo: "（仍可於下一頁修改上述資料）",
      supportedFormat: "[檔案格式: jpg, jpeg, png (不超過 8 MB)]",
      importantNoteTitle: "重要事項",
      importantNoteContent:
        "車輛登記號碼、牌照屆滿日期及車輛種類是處理申請的重要資料，如果系统未能成功由閣下所上載的文件正確辨認申請車輛的登記號碼、牌照屆滿日期或車輛種類，請前往有關停車場的缴費處，以人手方式遞交季租泊車服務申請。",
      close: "關閉",
      vehicleLicenseReminderTitle: "溫馨提示:",
      vehicleLicenseReminder: "請將「行車証」按照樣本規格垂直上傳",
      sampleImage: "樣本圖片",
      unacceptableImage: {
        example: "不獲接受的相片例子",
        incomplete: "不完整之行車證",
        irregular: "不規則行車證",
        shadow: "有陰影",
        tooSmall: "相片圖像太小",
        tooManyBackground: "背景太多",
      },
    },
    step3: {
      title: "填寫資料",
      vehicleRegistrationMark: "車輛登記號碼",
      vehicleLicenseExpireDate: "牌照屆滿日期",
      carType: "車輛類別",
      carTypeRemark: "(閣下必須選擇正確的車輛類別，否則閣下的申請會被拒絕。)",
      applicantName: "登記車主/申請人姓名",
      email: "電郵地址",
      phone: "聯絡電話",
      physicalDisability: "登記車主/申請人為肢體殘疾人士：",
      yes: "是",
      no: "否",
      upload: "上載文件",
      plzUpload: "請上載",
      parkingPermit: "傷殘人士泊車許可證",
      or: "或",
      registrationCard: "批准信",
      consentRemarks_1: "本人已細閱、明白及同意 ",
      consentRemarks_2: "“個人資料收集聲明”",
      consentRemarks_3: "及",
      consentRemarks_4: "“申請季租泊車服務說明書”",
      consentRemarks_5: " 中的 ",
      consentRemarks_6: "“申請購買季租泊車服務的條件”。",
      warning: {
        vehicleRegistrationMark: {
          empty: "請輸入車牌號碼",
          notMatch: "有部分資料不符。",
          notRecognised: "下列資料未能成功辨認：",
          invalid: "車牌號碼格式不符",
        },
        carType: "請選擇車輛種類",
        applicantName: "請輸入申請人姓名",
        email: "請輸入電郵地址",
        phone: "請輸入聯絡電話",
        disablePersonParkingPermit: "請上載傷殘人士泊車許可證或批准信",
        isAgreeConsent: "請詳閱及同意 “申請購買季租泊車服務的條件”",
        confirmEmail: {
          empty: "請輸入確認電郵地址",
          notMatch: "確認電郵地址與電郵地址不符",
        },
        invalidPhone: "電話號碼無效",
        invalidEmail: "電郵地址無效",
        vehicleLicenseExpireDate: "請輸入牌照屆滿日期",
      },
      remark: "備注 (如沒有特別情況，不需填寫)",
      confirmEmail: "確認電郵地址",
      amendInfo: "*如有任何更改或備註，本申請將須人手批核。",
    },
    step4: {
      title: "確認",
      successTitle: "申請已提交",
      applicationNumber: "你的申請編號是 ",
      na: "不適用",
      failTitle: "申請未成功！",
      tryAgain: "請重新嘗試。",
    },
    next: "下一頁",
    back: "返回",
    finish: "完成",
    confirm: "確認",
    errorMsg: {
      submitted: "此申請編號已曾經提交。",
      carparkNoCampaign: "此停車場未有任何活動。",
      licenceExpired:
        "車輛牌照已經過期，系統未能處理有關申請。如有需要，請前往有關停車場的繳費處或致電2804 2600查詢。",
      campaignExpired: "申請時段已過。",
      alreadyRegistered: "此車輛牌照號碼已曾經登記。",
      notFound: "未能找到相關紀錄。",
      invalidFileFormat: "錯誤文件格式",
    },
    overMaxImageLimit: "需上載少於8MB的檔案",
    invalidImageFormat: "錯誤文件格式",
  },
};
