import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import Button from "../../components/general/button";
import ContentCard from "../../components/general/contentCard";
import "./stepThree.css";
import Calendar from "../../components/general/calendar";
import CardView from "../../components/general/cardView";
import themeColor from "../../styles/themeColor";
import CheckButton from "../../components/general/checkButton";
import actions, { useActions } from "../../redux/actions";
import GeneralWarningModal from "./generalWarningModal";
import OCRWarningModal from "./ocrWarningModal";
import SpinnerOverlay from "../../components/general/spinnerOverlay";
import {attachmentType, errorMessage} from "../../helpers/constants";

const ONE_MEGABYTE = 1048576;

const defaultIcon = require("../../assets/images/register/step3/car_van.png");

const blueButtonStyle = {
  borderColor: themeColor.textColor,
  color: themeColor.textColor,
  background: themeColor.lightOptionColor,
};

const greyButtonStyle = {
  borderColor: themeColor.grey,
  color: themeColor.grey,
  background: themeColor.white,
};

const EngConsentRemark = () => {
  const { t } = useTranslation();
  const { campaign } = useSelector((state) => state.application);
  const {
    list,
    setting: { personalInformationCollectionStatement },
  } = useSelector((state) => state.campaign);
  const { guidanceNote } = list.find((c) => c._id === campaign) || {};

  return (
    <div>
      {t("register.step3.consentRemarks_1")}
      {personalInformationCollectionStatement &&
      personalInformationCollectionStatement.fileUrl ? (
        <a
          href={personalInformationCollectionStatement.fileUrl}
          target="_blank"
          style={{ color: themeColor.greenTextColor }}
        >
          {t("register.step3.consentRemarks_2")}
        </a>
      ) : (
        <span style={{ color: themeColor.greenTextColor }}>
          {t("register.step3.consentRemarks_2")}
        </span>
      )}
      {t("register.step3.consentRemarks_3")}
      {t("register.step3.consentRemarks_4")}
      {t("register.step3.consentRemarks_5")}
      {guidanceNote && guidanceNote.fileUrl ? (
        <a
          href={guidanceNote.fileUrl}
          target="_blank"
          style={{ color: themeColor.greenTextColor }}
        >
          {t("register.step3.consentRemarks_6")}
        </a>
      ) : (
        <span style={{ color: themeColor.greenTextColor }}>
          {t("register.step3.consentRemarks_6")}
        </span>
      )}
    </div>
  );
};

const ChiConsentRemark = () => {
  const { t } = useTranslation();
  const { campaign } = useSelector((state) => state.application);
  const {
    list,
    setting: { personalInformationCollectionStatement },
  } = useSelector((state) => state.campaign);
  const { guidanceNote } = list.find((c) => c._id === campaign) || {};

  return (
    <div>
      {t("register.step3.consentRemarks_1")}
      {personalInformationCollectionStatement &&
      personalInformationCollectionStatement.fileUrl ? (
        <a
          href={personalInformationCollectionStatement.fileUrl}
          target="_blank"
          style={{ color: themeColor.greenTextColor }}
        >
          {t("register.step3.consentRemarks_2")}
        </a>
      ) : (
        <span style={{ color: themeColor.greenTextColor }}>
          {t("register.step3.consentRemarks_2")}
        </span>
      )}
      {t("register.step3.consentRemarks_3")}
      {guidanceNote && guidanceNote.fileUrl ? (
        <a
          href={guidanceNote.fileUrl}
          target="_blank"
          style={{ color: themeColor.greenTextColor }}
        >
          {t("register.step3.consentRemarks_4")}
        </a>
      ) : (
        <span style={{ color: themeColor.greenTextColor }}>
          {t("register.step3.consentRemarks_4")}
        </span>
      )}
      {t("register.step3.consentRemarks_5")}
      {t("register.step3.consentRemarks_6")}
    </div>
  );
};

const StepThree = withRouter((props) => {
  const { history, location } = props;
  const { t, i18n } = useTranslation();
  const application = useSelector((state) => state.application);
  const applicationActions = useActions(actions.ApplicationActions);
  const { token } = useSelector((state) => state.account);
  const [showWarning, setShowWarning] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOCRWarning, setShowOCRWarning] = useState(false);
  const [modalData, setModalData] = useState({});

  const isStaffSite = location.pathname.includes("/staff");

  const handleUpload = (e) => {
    if (e.target.files[0].size > ONE_MEGABYTE * 8) {
      alert(t("register.overMaxImageLimit"));
      return;
    }
    if (
      !["image/jpeg", "image/png", "image/jpg"].includes(e.target.files[0].type)
    ) {
      alert(t("register.invalidImageFormat"));
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    applicationActions.uploadAttachment(
      "disablePersonParkingPermit",
      formData,
      attachmentType.DISABLE_PERSON_PARKING_PERMIT,
      token,
      (response) => {
        const data = {
          attachment: response.disablePersonParkingPermit,
          type: "disablePersonParkingPermit",
        };
        applicationActions.ocr(
          data,
          token,
          (response) => {
            const {
              valid,
              vehicleRegistrationMark,
              vehicleLicenseExpireDate,
            } = response.responseDetail;

            let match = true;

            if (
              (vehicleRegistrationMark !==
                application.vehicleRegistrationMark &&
                vehicleRegistrationMark) ||
              (vehicleLicenseExpireDate !==
                application.vehicleLicenseExpireDate &&
                vehicleLicenseExpireDate)
            ) {
              match = false;
            }

            setModalData({
              valid,
              vehicleRegistrationMark,
              vehicleLicenseExpireDate,
              match,
            });

            if (!valid || !match) setShowOCRWarning(true);
            setLoading(false);
          },
          () => {
            setModalData({
              valid: false,
              vehicleRegistrationMark: null,
              vehicleLicenseExpireDate: null,
              match: true,
            });
            setShowOCRWarning(true);
            setLoading(false);
          }
        );
      },
      (error) => {
        const errorMsg = errorMessage.find(
          (e) => e.code === error.responseCode
        );
        if (errorMsg) {
          alert(t(errorMsg.key));
        }
        setLoading(false);
      }
    );
  };
  const handleChange = (e) => {
    applicationActions.update({ [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setShowWarning(false);
    setShowOCRWarning(false);
  };
  const handleValidate = () => {
    const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

    applicationActions.update({ isStep3Completed: false });

    if (
      !application.vehicleRegistrationMark ||
      application.vehicleRegistrationMark === ""
    ) {
      setModalContent(
        t("register.step3.warning.vehicleRegistrationMark.empty")
      );
      return;
    }

    const validFormat = /^[A-HJ-NPR-Z0-9]+$/;
    if (!validFormat.test(application.vehicleRegistrationMark)) {
      setModalContent(
        t("register.step3.warning.vehicleRegistrationMark.invalid")
      );
      return;
    }

    if (
      !application.vehicleLicenseExpireDate ||
      application.vehicleLicenseExpireDate === ""
    ) {
      setModalContent(t("register.step3.warning.vehicleLicenseExpireDate"));
      return;
    }

    if (!application.vehicletype) {
      setModalContent(t("register.step3.warning.carType"));
      return;
    }

    if (!application.phone || application.phone === "") {
      setModalContent(t("register.step3.warning.phone"));
      return;
    }

    if (isNaN(application.phone) || application.phone.length < 8) {
      setModalContent(t("register.step3.warning.invalidPhone"));
      return;
    }

    if (!application.applicantName || application.applicantName === "") {
      setModalContent(t("register.step3.warning.applicantName"));
      return;
    }

    if (
      !(token && isStaffSite) &&
      (!application.email || application.email === "")
    ) {
      setModalContent(t("register.step3.warning.email"));
      return;
    }

    if (!(token && isStaffSite) && !emailRegex.test(application.email)) {
      setModalContent(t("register.step3.warning.invalidEmail"));
      return;
    }

    if (!(token && isStaffSite)) {
      if (!application.confirmEmail || application.confirmEmail === "") {
        setModalContent(t("register.step3.warning.confirmEmail.empty"));
        return;
      }

      if (application.confirmEmail !== application.email) {
        setModalContent(t("register.step3.warning.confirmEmail.notMatch"));
        return;
      }
    }

    if (
      application.isPhysicalDisability &&
      !application.disablePersonParkingPermit
    ) {
      setModalContent(t("register.step3.warning.disablePersonParkingPermit"));
      return;
    }

    if (!application.isAgreeConsent) {
      setModalContent(t("register.step3.warning.isAgreeConsent"));
      return;
    }

    applicationActions.update({ isStep3Completed: true });
  };

  const handleValidatePhone = () => {
    if (isNaN(application.phone) || application.phone.length < 8) {
      setModalContent(t("register.step3.warning.invalidPhone"));
      setShowWarning(true);
    }
  };

  const handleValidateEmail = () => {
    const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
    if (!(token && isStaffSite) && !emailRegex.test(application.email)) {
      setModalContent(t("register.step3.warning.invalidEmail"));
      setShowWarning(true);
    }
  };

  const handleValidateRegistrationMark = () => {
    if (
      !application.vehicleRegistrationMark ||
      application.vehicleRegistrationMark === ""
    ) {
      setModalContent(
        t("register.step3.warning.vehicleRegistrationMark.empty")
      );
      setShowWarning(true);
      return;
    }

    const validFormat = /^[A-HJ-NPR-Z0-9]+$/;
    if (!validFormat.test(application.vehicleRegistrationMark)) {
      setModalContent(
        t("register.step3.warning.vehicleRegistrationMark.invalid")
      );
      setShowWarning(true);
    }
  };

  useEffect(() => {
    handleValidate();
  }, [
    application.vehicleRegistrationMark,
    application.vehicleLicenseExpireDate,
    application.vehicletype,
    application.applicantName,
    application.phone,
    application.email,
    application.confirmEmail,
    application.isPhysicalDisability,
    application.disablePersonParkingPermit,
    application.isAgreeConsent,
  ]);

  const vehicles = useSelector((state) => state.vehicle);
  const vehicleActions = useActions(actions.VehicleActions);

  useEffect(() => {
    vehicleActions.getList();
  }, []);

  const handleRetrieveInfo = () => {
    if (token && isStaffSite)
      applicationActions.getRegisteredUserInfo(application.phone, token);
  };
  //
  if (!application.isStep2Completed) {
    if (token && isStaffSite) return <Redirect to="/staff/step2" />;
    return <Redirect to="/step2" />;
  }

  if (!application.isStep1Completed) {
    if (token && isStaffSite) return <Redirect to="/staff/step1" />;
    return <Redirect to="/step1" />;
  }

  return (
    <Container fluid>
      <Row>
        <ContentCard leftTitle={t("register.step3.title")}>
          <GeneralWarningModal
            show={showWarning}
            handleClose={handleClose}
            content={modalContent}
          />
          <OCRWarningModal
            show={showOCRWarning}
            handleClose={handleClose}
            data={modalData}
          />
          <div>
            <div className="input-field left">
              <span className="label">
                <span className="star">*</span>
                {t("register.step3.vehicleRegistrationMark")}
              </span>
              <input
                type="text"
                onChange={handleChange}
                name="vehicleRegistrationMark"
                value={application.vehicleRegistrationMark}
                onBlur={() => {
                  handleValidateRegistrationMark();
                }}
              />
            </div>
            <div className="input-field right">
              <span className="label">
                <span className="star">*</span>
                {`${t("register.step3.vehicleLicenseExpireDate")} `}
                {application.vehicleLicenseExpireDate !== "" && (
                  <>
                    {`(${moment(application.vehicleLicenseExpireDate).format(
                      "DD/MM/YYYY"
                    )})`}
                  </>
                )}
              </span>
              <CardView style={{ marginTop: 10, maxWidth: "100%" }}>
                <Calendar
                  locale={i18n.language === "zh" ? "zh" : "en"}
                  value={
                    application.vehicleLicenseExpireDate === ""
                      ? ""
                      : new Date(application.vehicleLicenseExpireDate)
                  }
                  onChange={(date) => {
                    applicationActions.update({
                      vehicleLicenseExpireDate: moment(date).format(
                        "YYYY-MM-DD"
                      ),
                    });
                  }}
                />
              </CardView>
            </div>
            <div className="input-field left">
              <span className="label">{t("register.step3.carType")}</span>
              <span className="remark" style={{ fontWeight: 400 }}>
                {t("register.step3.carTypeRemark")}
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {vehicles.list.map((vehicle) => {
                  return (
                    <Button
                      style={{
                        padding: "1vh 1vw",
                        marginRight: 15,
                        width: 120,
                        marginTop: 15,
                      }}
                      active={vehicle._id === application.vehicletype}
                      onClick={() => {
                        applicationActions.update({
                          vehicletype: vehicle._id,
                        });
                      }}
                      buttonType="icon"
                      iconStyle={{
                        margin: 5,
                        height: 50,
                        aspectRatio: 1,
                        objectFit: "contain",
                      }}
                      icon={vehicle.icon ? vehicle.icon.fileUrl : defaultIcon}
                      tag={
                        i18n.language === "zh" ? vehicle.name_zh : vehicle.name
                      }
                    />
                  );
                })}
              </div>
            </div>
            <div className="sep-0" />
            <div className="input-field left">
              <span className="label">{t("register.step3.phone")}</span>
              <input
                type="text"
                name="phone"
                onChange={handleChange}
                value={application.phone}
                onBlur={() => {
                  handleValidatePhone();
                  handleRetrieveInfo();
                }}
              />
            </div>
            <div className="input-field right">
              <span className="label">{t("register.step3.remark")}</span>
              <textarea
                name="remark"
                onChange={handleChange}
                value={application.remark}
              />
            </div>
            <div className="input-field left">
              <span className="label">{t("register.step3.applicantName")}</span>
              <input
                type="text"
                name="applicantName"
                onChange={handleChange}
                value={application.applicantName}
              />
            </div>
            <div className="sep-0" />
            {/* {!(token && isStaffSite) && ( */}
            <>
              <div className="input-field left">
                <span className="label">{t("register.step3.email")}</span>
                <input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  value={application.email}
                  onBlur={handleValidateEmail}
                />
              </div>
              <div className="input-field right">
                {!isStaffSite && (
                  <>
                    <span className="label">
                      {t("register.step3.confirmEmail")}
                    </span>
                    <input
                      type="text"
                      name="confirmEmail"
                      onChange={handleChange}
                      value={application.confirmEmail}
                    />
                  </>
                )}
              </div>
            </>

            <div className="sep-0" />

            <div className="input-field left">
              <span className="label">
                {t("register.step3.physicalDisability")}
              </span>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CheckButton
                  buttonType="radio"
                  label={t("register.step3.yes")}
                  name="isPhysicalDisability"
                  value="yes"
                  checked={application.isPhysicalDisability}
                  onChange={(e) =>
                    applicationActions.update({
                      isPhysicalDisability: true,
                    })
                  }
                />
                <CheckButton
                  buttonType="radio"
                  label={t("register.step3.no")}
                  name="isPhysicalDisability"
                  value="no"
                  checked={!application.isPhysicalDisability}
                  onChange={(e) =>
                    applicationActions.update({
                      isPhysicalDisability: false,
                      disablePersonParkingPermit: null,
                    })
                  }
                />
              </div>
              {application.isPhysicalDisability && (
                <div>
                  <div
                    className="left"
                    style={{ marginBottom: 10, marginRight: 10 }}
                  >
                    <Button style={{ padding: "1vh 1vw", fontSize: "0.9rem" }}>
                      <label style={{ margin: 0 }}>
                        {t("register.step3.upload")}
                        <input
                          type="file"
                          onChange={handleUpload}
                          style={{ display: "none" }}
                          disabled={loading}
                          onClick={(e) => (e.target.value = null)}
                          accept="image/png,image/jpeg, image/jpg"
                        />
                      </label>
                    </Button>
                  </div>
                  <div className="remark right">
                    <span style={{ color: themeColor.redTextColor }}>*</span>
                    {t("register.step3.plzUpload")}
                    &nbsp;
                    <span style={{ color: themeColor.greenTextColor }}>
                      {t("register.step3.parkingPermit")}
                      &nbsp;
                    </span>
                    {t("register.step3.or")}
                    &nbsp;
                    <span style={{ color: themeColor.greenTextColor }}>
                      {t("register.step3.registrationCard")}
                    </span>
                    {i18n.language === "zh" ? "。" : "."}
                  </div>
                </div>
              )}
            </div>
            <div className="sep-0" />
            <div className="input-field left">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <CheckButton
                  buttonType="checkbox"
                  label={
                    i18n.language === "zh" ? (
                      <ChiConsentRemark />
                    ) : (
                      <EngConsentRemark />
                    )
                  }
                  value={application.isAgreeConsent}
                  checked={application.isAgreeConsent}
                  onChange={(e) => {
                    applicationActions.update({
                      isAgreeConsent: !application.isAgreeConsent,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </ContentCard>
      </Row>

      <Row
        style={{
          padding: "0px 15px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 20,
        }}
      >
        <Button
          onClick={() => {
            history.push({
              pathname: token && isStaffSite ? "/staff/step2" : "/step2",
              state: { previousScreen: location.pathname },
            });
          }}
          disabled={loading}
        >
          {t("register.back")}
        </Button>
        <Button
          onClick={() => {
            if (application.isStep3Completed) {
              history.push({
                pathname: token && isStaffSite ? "/staff/step4" : "/step4",
                state: { previousScreen: location.pathname },
              });
            } else {
              setShowWarning(true);
            }
          }}
          style={
            application.isStep3Completed && !loading
              ? blueButtonStyle
              : greyButtonStyle
          }
          disabled={loading}
        >
          {t("register.next")}
        </Button>
      </Row>

      <Row
        style={{
          padding: "0px 15px",
          marginBottom: 20,
        }}
      >
        <span className="star">{t("register.step3.amendInfo")}</span>
      </Row>
      <SpinnerOverlay loading={loading} />
    </Container>
  );
});

export default StepThree;
