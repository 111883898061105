import incomplete1 from "../../assets/images/register/step2/unacceptableImages/incomplete1.jpeg";
import incomplete2 from "../../assets/images/register/step2/unacceptableImages/incomplete2.jpg";
import irregular1 from "../../assets/images/register/step2/unacceptableImages/irregular1.jpg";
import irregular2 from "../../assets/images/register/step2/unacceptableImages/irregular2.jpg";
import shadow from "../../assets/images/register/step2/unacceptableImages/shadow.jpg";
import tooManyBackground1 from "../../assets/images/register/step2/unacceptableImages/too-many-background1.jpg";
import tooManyBackground2 from "../../assets/images/register/step2/unacceptableImages/too-many-background2.jpg";
import tooSmall from "../../assets/images/register/step2/unacceptableImages/too-small.jpg";

const unacceptableImages = [
  {
    image: incomplete1,
    description: "register.step2.unacceptableImage.incomplete",
  },
  {
    image: incomplete2,
    description: "register.step2.unacceptableImage.incomplete",
  },
  {
    image: irregular1,
    description: "register.step2.unacceptableImage.irregular",
  },
  {
    image: irregular2,
    description: "register.step2.unacceptableImage.irregular",
  },
  {
    image: shadow,
    description: "register.step2.unacceptableImage.shadow",
  },
  {
    image: tooManyBackground1,
    description: "register.step2.unacceptableImage.tooManyBackground",
  },
  {
    image: tooManyBackground2,
    description: "register.step2.unacceptableImage.tooManyBackground",
  },
  {
    image: tooSmall,
    description: "register.step2.unacceptableImage.tooSmall",
  },
];

export { unacceptableImages };
