import baseAPIHelper from "./baseAPIHelper";

function getList(token, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/vehicletype`;
  if (token) {
    const urlWithToken = new URL(url);
    urlWithToken.searchParams.set("access_token", token);
    url = urlWithToken.toString();
  }
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

const vehicleAPIHelper = {
  getList,
};

export default vehicleAPIHelper;
