import React, { useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import actions, { useActions } from "../../redux/actions";
import CardView from "../../components/general/cardView";
import LangSwitch from "../../components/general/langSwitch";
import Button from "../../components/general/button";
import themeColor from "../../styles/themeColor";
import "./login.css";
import Modal from "../../components/general/modal";
import FailPage from "./failPage";

const StaffLogin = withRouter((props) => {
  const { t } = useTranslation();
  const { history, location } = props;
  const accountActions = useActions(actions.AccountActions);
  const applicationActions = useActions(actions.ApplicationActions);
  const [showModal, setShowModal] = useState(false);
  const [staffNumber, setStaffNumber] = useState("");
  const { token } = useSelector((state) => state.account);
  const [modalContent, setModalContent] = useState("");

  const handleLogin = () => {
    accountActions.login(
      staffNumber,
      () => {
        applicationActions.reset();
        history.push({
          pathname: "/staff/step1",
          state: { previousScreen: location.pathname },
        });
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          setModalContent(t("authentication.unauthorized"));
        } else {
          setModalContent("");
        }
        setShowModal(true);
      }
    );
  };

  const handleChange = (e) => {
    setStaffNumber(e.target.value);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  if (token) {
    return <Redirect to="/staff/step1" />;
  }

  return (
    <Container
      fluid
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: themeColor.darkOptionColor,
        display: "flex",
        alignItems: "center",
        paddingLeft: 0,
        margin: 0,
      }}
    >
      <Modal show={showModal}>
        <FailPage handleClose={handleClose} content={modalContent} />
      </Modal>
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h4
          style={{
            textAlign: "center",
            color: themeColor.greenTextColor,
          }}
        >
          {t("header.title")}
        </h4>
        <h4 style={{ color: themeColor.white, margin: 20 }}>
          {t("authentication.staffLogin")}
        </h4>
        <CardView
          style={{
            backgroundColor: themeColor.white,
            minWidth: "65vw",
            padding: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="label">{t("authentication.staffNumber")}</span>
          <input
            type="text"
            name="no"
            onChange={handleChange}
            value={staffNumber}
          />
          <div
            style={{
              display: "float",
              marginTop: 20,
            }}
          >
            <div style={{ float: "left" }}>
              <Button
                style={{ padding: "10px 20px 10px 20px" }}
                onClick={handleLogin}
              >
                {t("authentication.login")}
              </Button>
              <Link
                style={{
                  marginLeft: 20,
                  color: themeColor.textColor,
                  textDecoration: "underline",
                }}
                to="/"
              >
                {t("authentication.notStaff")}
              </Link>
            </div>
            <div
              style={{
                float: "right",
              }}
            >
              <LangSwitch />
            </div>
          </div>
        </CardView>
      </Col>
    </Container>
  );
});

export default StaffLogin;
