import React from "react";
import { Redirect, useLocation, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import actions, { useActions } from "../../redux/actions";
import "./login.css";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Login = withRouter(() => {
  const applicationActions = useActions(actions.ApplicationActions);
  const { token } = useSelector((state) => state.account);
  const appNo = useQuery().get("app_no");

  if (token) {
    applicationActions.reset();
    if (appNo) {
      applicationActions.update({ no: appNo });
    }
    return <Redirect to="/staff/step1" />;
  }

  return <Redirect to="/" />;
});

export default Login;
