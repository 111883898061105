import React from "react";
import PropTypes from "prop-types";
import themeColor from "../../../styles/themeColor";

const TagButton = (props) => {
  const { style, children, tag, active } = props;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div>
        <span
          style={{
            borderStyle: "solid",
            borderWidth: active === true ? 2 : 1,
            borderColor: themeColor.borderColor,
            borderRadius: 5,
            background: themeColor.backgroundColor,
            padding: "0.35rem",
            fontSize: "0.8rem",
            fontWeight: active === true ? "600" : "400",
            ...style,
          }}
        >
          {tag}
        </span>
      </div>
      <div>{children}</div>
    </div>
  );
};

TagButton.propTypes = {
  style: PropTypes.object,
  tag: PropTypes.string,
  active: PropTypes.bool,
};

export default TagButton;
