const procedures = [
  "register.step1.title",
  "register.step2.title",
  "register.step3.title",
  "register.step4.title",
];

const staffProcedures = [
  "register.step1.staff.title",
  "register.step2.title",
  "register.step3.title",
  "register.step4.title",
];

const steps = [
  { pathname: "/", step: 1 },
  { pathname: "/step1", step: 1 },
  { pathname: "/step2", step: 2 },
  { pathname: "/step3", step: 3 },
  { pathname: "/step4", step: 4 },
];

const staffSteps = [
  { pathname: "/staff", step: 1 },
  { pathname: "/staff/step1", step: 1 },
  { pathname: "/staff/step2", step: 2 },
  { pathname: "/staff/step3", step: 3 },
  { pathname: "/staff/step4", step: 4 },
];

const errorMessage = [
  {
    code: "66002",
    key: "register.errorMsg.submitted",
  },
  {
    code: "66003",
    key: "register.errorMsg.carparkNoCampaign",
  },
  {
    code: "61004",
    key: "register.errorMsg.licenceExpired",
  },
  {
    code: "61008",
    key: "register.errorMsg.campaignExpired",
  },
  {
    code: "61012",
    key: "register.errorMsg.alreadyRegistered",
  },
  {
    code: "003",
    key: "register.errorMsg.notFound",
  },
  {
    code: "62004",
    key: "register.errorMsg.invalidFileFormat",
  },
];

const attachmentType = {
  VEHICLE_LICENSE: "vehicleLicense",
  DISABLE_PERSON_PARKING_PERMIT: "disablePersonParkingPermit",
};

export {
  procedures,
  steps,
  staffProcedures,
  staffSteps,
  errorMessage,
  attachmentType,
};
