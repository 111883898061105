import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import StepItem from "./stepItem";

const ProgressBar = (props) => {
  const { t, i18n } = useTranslation();
  const {
    barContainerStyle = {},
    stepContainerStyle = {},
    stepNumberStyle = {},
    stepLabelStyle = {},
    activeIndex = 1,
    procedures = ["Step 1", "Step 2", "Step 3"],
  } = props;
  const progressBarContainer = useRef(null);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "stretch",
        flexDirection: "column",
        ...barContainerStyle,
      }}
    >
      <div
        ref={progressBarContainer}
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "row",
          zIndex: 1,
          ...stepContainerStyle,
        }}
      >
        {procedures.map((step, index) => {
          return (
            <StepItem
              progressBarContainer={progressBarContainer}
              activeIndex={activeIndex}
              index={index}
              label={step}
              stepItemContainerStyle={{
                width: `${Math.floor(100 / procedures.length)}%`,
              }}
              stepNumberStyle={stepNumberStyle}
              stepLabelStyle={stepLabelStyle}
            />
          );
        })}
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  barContainerStyle: PropTypes.object,
  stepContainerStyle: PropTypes.object,
  stepNumberStyle: PropTypes.object,
  stepLabelStyle: PropTypes.object,
  activeIndex: PropTypes.number,
  procedures: PropTypes.arrayOf(PropTypes.string),
};

export default ProgressBar;
