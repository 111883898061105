import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import ProgressBar from "../progressBar";
import {
  procedures,
  staffProcedures,
  staffSteps,
  steps,
} from "../../../helpers/constants";
import LangSwitch from "../langSwitch";
import AuthButton from "../authButton";
import themeColor from "../../../styles/themeColor";

const Header = withRouter((props) => {
  const { location } = props;
  const { t, i18n } = useTranslation();
  const [step, setSteps] = useState({ pathname: "/", step: 1 });
  const { token } = useSelector((state) => state.account);
  const campaigns = useSelector((state) => state.campaign);
  const [paymentLinkComp, setPaymentLinkComp] = useState();
  const [epayLink, setEpayLink] = useState("https://epay.tdcarpark.com.hk/");
  const appSteps =
    token && location.pathname.includes("/staff") ? staffSteps : steps;
  useEffect(() => {
    const newStep = appSteps.find(
      (item) => item.pathname === location.pathname
    ) || {
      pathname: "/",
      step: 1,
    };
    setSteps(newStep);
  }, [location.pathname]);

  useEffect(() => {
    if (campaigns.withinPaymentPeriod) {
      return setPaymentLinkComp(
        <a
          style={{
            color: themeColor.redTextColor,
            textDecoration: "underline",
            marginBottom: 0,
          }}
          href={epayLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("header.payLink")}
        </a>
      );
    }

    return setPaymentLinkComp(
      <label
        style={{
          color: themeColor.greenTextColor,
          textDecoration: "underline",
          marginBottom: 0,
          cursor: "unset",
        }}
      >
        {t("header.payLink")}
      </label>
    );
  }, [campaigns.withinPaymentPeriod, i18n.language]);

  const procedureKeys =
    token && location.pathname.includes("/staff")
      ? staffProcedures
      : procedures;
  return (
    <Container fluid>
      <h5
        style={{
          textAlign: "center",
          color: themeColor.greenTextColor,
        }}
      >
        <Trans
          t={t}
          i18nKey="message"
          components={{ newline: <p style={{ marginBottom: 2 }} /> }}
        >
          {t("header.title")}
          {t("header.firstHalf")}
        </Trans>

        {paymentLinkComp}

        <Trans
          t={t}
          i18nKey="message"
          components={{ newline: <p style={{ marginBottom: 2 }} /> }}
        >
          {t("header.secondHalf")}
        </Trans>
      </h5>
      <Row>
        <Col xs={12} sm={10} md={7} lg={6}>
          <div>
            <ProgressBar
              activeIndex={step.step}
              barContainerStyle={{ marginTop: 30 }}
              procedures={procedureKeys.map((key) => t(key))}
            />
          </div>
        </Col>
        <Col xs={12} sm={2} md={5} lg={6}>
          <div className="float-right" style={{ margin: 20 }}>
            <LangSwitch />
          </div>
          <div className="float-right" style={{ margin: "20px 0 20px 20px" }}>
            <AuthButton />
          </div>
        </Col>
      </Row>
    </Container>
  );
});

export default Header;
