import React, { useEffect, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import "moment/locale/zh-hk";
import "moment/locale/en-gb";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Container, Row } from "react-bootstrap";
import actions, { useActions } from "../../redux/actions";
import NewsModal from "./newsModal";
import Tab from "./tab";
import Button from "../../components/general/button";
import themeColor from "../../styles/themeColor";

const blueButtonStyle = {
  borderColor: themeColor.textColor,
  color: themeColor.textColor,
  background: themeColor.lightOptionColor,
};

const greyButtonStyle = {
  borderColor: themeColor.grey,
  color: themeColor.grey,
  background: themeColor.white,
};

const StepOne = withRouter((props) => {
  const { history, location } = props;
  const { t } = useTranslation();
  const application = useSelector((state) => state.application);
  const applicationActions = useActions(actions.ApplicationActions);
  const { token } = useSelector((state) => state.account);
  const announcementActions = useActions(actions.AnnouncementActions);

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const campaigns = useSelector((state) => state.campaign);
  const campaignActions = useActions(actions.CampaignActions);

  const endOfDay = moment().endOf("day");

  const availableCampaigns = campaigns.list.filter((campaign) =>
    moment(campaign.from).isSameOrBefore(endOfDay)
  );
  const comingCampaigns = campaigns.list.filter((campaign) =>
    moment(campaign.from).isAfter(endOfDay)
  );

  useEffect(() => {
    campaignActions.getList();
  }, []);

  if (token && location.pathname.includes("/staff")) {
    return <Redirect to="/staff/step1" />;
  }

  useEffect(() => {
    if (application.campaign) {
      applicationActions.update({ isStep1Completed: true });
    } else {
      applicationActions.update({ isStep1Completed: false });
    }
  }, [application.campaign]);

  useEffect(() => {
    announcementActions.getList(null, (response) => {
      if (response.length >= 1) {
        setShowModal(true);
      }
    });
  }, []);

  return (
    <Container fluid>
      <NewsModal show={showModal} handleClose={handleClose} />
      <Tab
        campaigns={availableCampaigns}
        remarks={t("register.step1.available")}
      />
      <Tab
        campaigns={comingCampaigns}
        remarks={t("register.step1.coming")}
        disabled
      />

      <Row
        style={{
          padding: "0px 15px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <div />
        <Button
          onClick={() => {
            if (application.isStep1Completed) {
              history.push({
                pathname: "/step2",
                state: { previousScreen: location.pathname },
              });
            }
          }}
          style={
            application.isStep1Completed ? blueButtonStyle : greyButtonStyle
          }
        >
          {t("register.next")}
        </Button>
      </Row>
    </Container>
  );
});

export default StepOne;
