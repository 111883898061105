import { useTranslation } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";
import ContentCard from "../../components/general/contentCard";
import themeColor from "../../styles/themeColor";
import AnimatedIcons from "../../components/general/animatedIcons";

const SuccessPage = (props) => {
  const { t, i18n } = useTranslation();
  const application = useSelector((state) => state.application);

  return (
    <ContentCard leftTitle={t("register.step4.successTitle")}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          color: themeColor.textColor,
          fontWeight: 500,
          fontSize: "1.2rem",
        }}
      >
        <AnimatedIcons.SuccessIcon />
        <span style={{ marginBottom: 20 }}>
          {t("register.step4.successTitle")}
          {i18n.language === "zh" ? "！" : "!"}
        </span>
        <span style={{ textAlign: "center" }}>
          {t("register.step4.applicationNumber")}
          <span
            style={{ color: themeColor.greenTextColor, fontSize: "1.4rem" }}
          >
            {application.applicationNumber}
          </span>
          {i18n.language === "zh" ? "。" : "."}
        </span>
      </div>
    </ContentCard>
  );
};

export default SuccessPage;
