import ActionTypes from "./types";
import vehicleAPIHelper from "../../helpers/api/vehicleAPIHelper";

/**
 * Get list for vehicle types
 * @param {String} [token=null] (Optional) Token for API calling, default using the application token of user site. Reserved for staff login.
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
function getList(token = null, successCallback = null, failCallback = null) {
  return (dispatch) => {
    vehicleAPIHelper.getList(
      token,
      (response) => {
        const {
          responseDetail: { data },
        } = response;
        dispatch({
          type: ActionTypes.VEHICLE_GET_LIST,
          data,
        });
        if (typeof successCallback === "function") successCallback(data);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

const VehicleActions = { getList };
export default VehicleActions;
