import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import themeColor from "../../../styles/themeColor";

const SpinnerOverlay = (props) => {
  const { style, spinnerStyle, loading } = props;

  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: themeColor.white,
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
        opacity: 0.4,
        display: loading ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <Spinner
        animation="border"
        style={{
          height: 80,
          width: 80,
          ...spinnerStyle,
        }}
      />
    </div>
  );
};

SpinnerOverlay.propTypes = {
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  spinnerStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
};

export default SpinnerOverlay;
