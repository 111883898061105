import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./checkbox";
import Radio from "./radio";

const CheckButton = (props) => {
  const {
    style,
    containerStyle,
    onChange,
    checked,
    buttonType,
    label,
    labelStyle,
    value,
    name,
  } = props;
  function renderButtonDisplay() {
    switch (buttonType) {
      case "checkbox":
        return (
          <Checkbox
            style={style}
            onChange={onChange}
            checked={checked}
            value={value}
            name={name}
          />
        );
      case "radio":
        return (
          <Radio
            style={style}
            onChange={onChange}
            checked={checked}
            value={value}
            name={name}
          />
        );
      default:
        return (
          <Checkbox
            style={style}
            onChange={onChange}
            checked={checked}
            value={value}
            name={name}
          />
        );
    }
  }

  return (
    <div>
      <label className="container" style={{ ...containerStyle }}>
        <span className="selection" style={{ ...labelStyle }}>
          {label}
        </span>
        {renderButtonDisplay()}
      </label>
    </div>
  );
};

CheckButton.propTypes = {
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  buttonType: PropTypes.string,
  label: PropTypes.string,
  labelStyle: PropTypes.object,
  value: PropTypes.string,
};

export default CheckButton;
