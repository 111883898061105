import moment from "moment";
import ActionTypes from "./types";
import campaignAPIHelper from "../../helpers/api/campaignAPIHelper";

/**
 * Get list for campaigns
 * @param {String} [token=null] (Optional) Token for API calling, default using the application token of user site. Reserved for staff login.
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
function getList(token = null, successCallback = null, failCallback = null) {
  return (dispatch) => {
    campaignAPIHelper.getList(
      token,
      (response) => {
        let {
          responseDetail: { data, setting, withinPaymentPeriod },
        } = response;
        const startOfDay = moment().startOf("day");
        data = data.filter((campaign) =>
          moment(campaign.to).isSameOrAfter(startOfDay)
        );
        dispatch({
          type: ActionTypes.CAMPAIGN_GET_LIST,
          data,
          setting,
          withinPaymentPeriod,
        });
        if (typeof successCallback === "function") successCallback(data);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

const CampaignActions = { getList };
export default CampaignActions;
