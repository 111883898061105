import ActionTypes from "./types";
import applicationAPIHelper from "../../helpers/api/applicationAPIHelper";
import vehicleAPIHelper from "../../helpers/api/vehicleAPIHelper";

/**
 * Update states of application reducer
 * @param {Object} data Object of fields to be updated
 * @returns {{data: *, type: string}} Redux Action
 */
function update(data) {
  return {
    type: ActionTypes.APPLICATION_UPDATE,
    data,
  };
}

/**
 * Submission of application
 * @param {String} documentField Field to be updated for the attachment upload
 * @param {FormData} attachment Attachment to be uploaded
 * @param {String} [token=null] (Optional) Token for API calling, default using the application token of user site. Reserved for staff login.
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[any]=)} Dispatch function of Redux action
 */
function uploadAttachment(
  documentField,
  attachment,
  type,
  token = null,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    applicationAPIHelper.uploadAttachment(
      token,
      attachment,
      type,
      (response) => {
        const { responseDetail } = response;
        let data = {};
        if (documentField === "vehicleLicenseCopy") {
          data = {
            vehicleLicenseCopy: responseDetail._id,
            vehicleLicenseCopy_url: responseDetail.fileUrl,
          };
        } else if (documentField === "disablePersonParkingPermit") {
          data = {
            disablePersonParkingPermit: responseDetail._id,
            disablePersonParkingPermit_url: responseDetail.fileUrl,
          };
        } else if (documentField === "applicationHardCopy") {
          data = {
            applicationHardCopy: responseDetail._id,
            applicationHardCopy_url: responseDetail.fileUrl,
          };
        }
        dispatch({
          type: ActionTypes.APPLICATION_UPLOAD_ATTACHMENT,
          data,
        });
        if (typeof successCallback === "function") successCallback(data);
      },
      (error) => {
        let data = {};
        if (documentField === "vehicleLicenseCopy") {
          data = {
            vehicleLicenseCopy: null,
            vehicleLicenseCopy_url: "",
          };
        } else if (documentField === "disablePersonParkingPermit") {
          data = {
            disablePersonParkingPermit: null,
            disablePersonParkingPermit_url: "",
          };
        } else if (documentField === "applicationHardCopy") {
          data = {
            applicationHardCopy: null,
            applicationHardCopy_url: "",
          };
        }
        dispatch({
          type: ActionTypes.APPLICATION_UPLOAD_ATTACHMENT,
          data,
        });
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * Submission of application
 * @param {Object} data Application information to be submitted
 * @param {String} [token=null] (Optional) Token for API calling, default using the application token of user site. Reserved for staff login.
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
function submit(
  data,
  token = null,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    applicationAPIHelper.submit(
      token,
      data,
      (response) => {
        dispatch({
          type: ActionTypes.APPLICATION_UPDATE,
          data: {
            isSubmitted: true,
            applicationNumber: response.responseDetail.no,
          },
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        dispatch({
          type: ActionTypes.APPLICATION_UPDATE,
          data: { isSubmitted: false, applicationNumber: "" },
        });
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * Reset states of application reducer
 * @returns {{type: string}} Redux Action
 */
function reset() {
  return {
    type: ActionTypes.APPLICATION_RESET,
  };
}
/**
 * Get vehicle registration mark and expire date via ocr
 * @param {Object} data Document information to be recognised
 * @param {String} [token=null] (Optional) Token for API calling, default using the application token of user site. Reserved for staff login.
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)}
 */
function ocr(data, token = null, successCallback = null, failCallback = null) {
  return (dispatch) => {
    applicationAPIHelper.ocr(
      token,
      data,
      (response) => {
        const {
          vehicleRegistrationMark,
          vehicleLicenseExpireDate,
          vehicleType: vehicleTypeEnum,
          _id,
        } = response.responseDetail;
        if (data.type === "vehicleLicense") {
          let dispatchData = {
            vehicleRegistrationMark,
            vehicleLicenseExpireDate,
            vehicleTypeEnum,
            vehicletype: "",
            vehicleLicenseCopyOCR: _id,
          };
          if (vehicleTypeEnum) {
            vehicleAPIHelper.getList(
              token,
              (vehicleResponse) => {
                const vehicleTypeObject = vehicleResponse.responseDetail.data.find(
                  (item) => item.ns_identifiers.includes(vehicleTypeEnum)
                );
                dispatchData = {
                  ...dispatchData,
                  vehicletype: vehicleTypeObject._id,
                  vehicleTypeEnum,
                };
                dispatch({
                  type: ActionTypes.APPLICATION_OCR,
                  data: dispatchData,
                });
              },
              (e) => {
                throw e;
              }
            );
          } else {
            dispatch({
              type: ActionTypes.APPLICATION_OCR,
              data: dispatchData,
            });
          }
        } else if (data.type === "disablePersonParkingPermit") {
          dispatch({
            type: ActionTypes.APPLICATION_OCR,
            data: {
              disablePersonParkingPermitOCR: _id,
            },
          });
        }
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (data.type === "vehicleLicense") {
          dispatch({
            type: ActionTypes.APPLICATION_OCR,
            data: {
              vehicleRegistrationMark: "",
              vehicleLicenseExpireDate: "",
              vehicletype: "",
              vehicleTypeEnum: "",
              vehicleLicenseCopyOCR: null,
            },
          });
        } else if (data.type === "disablePersonParkingPermit") {
          dispatch({
            type: ActionTypes.APPLICATION_OCR,
            data: {
              disablePersonParkingPermitOCR: null,
            },
          });
        }
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

function getApplicationInfo(
  no,
  token = null,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    applicationAPIHelper.getApplicationInfo(
      token,
      no,
      (response) => {
        const { vehicletype, carpark, campaign } = response.responseDetail;
        dispatch({
          type: ActionTypes.APPLICATION_UPDATE,
          data: {
            vehicletype: vehicletype._id,
            carpark: carpark._id,
            campaign: campaign._id,
          },
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        dispatch({
          type: ActionTypes.APPLICATION_UPDATE,
          data: {
            vehicletype: null,
            carpark: null,
            campaign: null,
          },
        });
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

function getRegisteredUserInfo(
  phoneNo,
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    applicationAPIHelper.getRegisteredUserInfo(
      token,
      phoneNo,
      (response) => {
        const { applicantName } = response.responseDetail;
        dispatch({
          type: ActionTypes.APPLICATION_UPDATE,
          data: {
            applicantName,
          },
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

const ApplicationActions = {
  update,
  submit,
  reset,
  uploadAttachment,
  ocr,
  getApplicationInfo,
  getRegisteredUserInfo,
};

export default ApplicationActions;
