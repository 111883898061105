import React from "react";
import PropTypes from "prop-types";
import themeColor from "../../../styles/themeColor";
import TagButton from "./tagButton";
import IconButton from "./iconButton";

const Button = (props) => {
  const {
    style,
    children,
    onClick,
    active,
    buttonType,
    tag,
    tagStyle,
    iconStyle,
    icon,
    disabled,
  } = props;
  function renderButtonContent() {
    switch (buttonType) {
      case "tag":
        return (
          <TagButton tag={tag} style={tagStyle} active={active}>
            {children}
          </TagButton>
        );
      case "icon":
        return (
          <IconButton
            tag={tag}
            icon={icon}
            tagStyle={tagStyle}
            iconStyle={iconStyle}
          >
            {children}
          </IconButton>
        );
      default:
        return children;
    }
  }

  return (
    <button
      style={{
        textAlign: "center",
        fontSize: "1rem",
        letterSpacing: 0,
        color: themeColor.textColor,
        opacity: 1,
        borderColor: themeColor.borderColor,
        borderWidth: active === false ? 1 : 2,
        borderStyle: "solid",
        borderRadius: 10,
        background:
          active === true
            ? themeColor.darkOptionColor
            : themeColor.lightOptionColor,
        outline: "none",
        padding: "2vh 5vw 2vh 5vw",
        boxShadow: `0px 3px 6px ${themeColor.shadowGrey}`,
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {renderButtonContent()}
    </button>
  );
};

Button.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  buttonType: PropTypes.string,
  tag: PropTypes.string,
  tagStyle: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Button;
