import { Col, Row } from "react-bootstrap";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../components/general/button";
import ContentCard from "../../components/general/contentCard";
import actions, { useActions } from "../../redux/actions";
import themeColor from "../../styles/themeColor";

const Tab = (props) => {
  const { t, i18n } = useTranslation();
  const { campaigns, remarks = "", disabled = false } = props;
  const application = useSelector((state) => state.application);
  const applicationActions = useActions(actions.ApplicationActions);

  return (
    <Row>
      <ContentCard
        leftTitle={t("register.step1.campaignTitle") + remarks}
        remarks={remarks}
      >
        <Row>
          {campaigns.map((campaign) => (
            <Col xs={12} sm={6} md={4} lg={3}>
              <Button
                style={{ width: "100%", padding: 15, marginTop: 20 }}
                buttonType="tag"
                tag={t(`register.step1.${campaign.carpark.region}`)}
                tagStyle={{
                  display: "inline-block",
                  width: "4em",
                }}
                onClick={() => {
                  if (!disabled) {
                    applicationActions.update({
                      carpark: campaign.carpark._id,
                      campaign: campaign._id,
                    });
                  }
                }}
                active={campaign._id === application.campaign}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    fontSize: "0.85rem",
                  }}
                >
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "1rem",
                      fontWeight:
                        campaign._id === application.campaign ? 600 : 400,
                    }}
                  >
                    {i18n.language === "zh"
                      ? campaign.carpark.name_zh
                      : campaign.carpark.name}
                  </span>
                  <span style={{ marginLeft: 5 }}>
                    {moment(campaign.parkingFrom).format("MM/YYYY")}
                    {" - "}
                    {moment(campaign.parkingTo).format("MM/YYYY")}
                  </span>
                  <span style={{ marginLeft: 5 }}>
                    <span style={{ fontWeight: 600 }}>
                      {t("register.step1.submissionDate")}
                    </span>
                    {moment(campaign.from).format("DD/MM/YYYY")}
                    {" - "}
                    {moment(campaign.to).format("DD/MM/YYYY")}
                  </span>
                </div>
              </Button>
            </Col>
          ))}
        </Row>
        {campaigns.length === 0 && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <span
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                color: themeColor.textColor,
              }}
            >
              {t("register.step1.noResult")}
            </span>
          </Row>
        )}
      </ContentCard>
    </Row>
  );
};

export default Tab;
