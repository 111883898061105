import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Modal from "../../components/general/modal";
import themeColor from "../../styles/themeColor";
import cross_icon from "../../assets/images/register/step2/cancel-80.png";
import close_icon from "../../assets/images/register/step2/cross.png";
import Button from "../../components/general/button";

const OCRWarningModal = (props) => {
  const { show, handleClose, data } = props;
  const { t, i18n } = useTranslation();
  const application = useSelector((state) => state.application);
  return (
    <Modal show={show} onHide={handleClose}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 20,
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <div style={{ paddingTop: 20 }}>
            <div>
              <span
                style={{
                  color: themeColor.textColor,
                  fontSize: "1.3rem",
                }}
              >
                {!data.valid
                  ? t(
                      "register.step3.warning.vehicleRegistrationMark.notRecognised"
                    )
                  : ""}
              </span>
            </div>
            <div style={{ marginTop: !data.valid ? 20 : 0 }}>
              <span
                style={{
                  color: themeColor.textColor,
                  fontSize: "1.3rem",
                }}
              >
                {!data.match
                  ? t("register.step3.warning.vehicleRegistrationMark.notMatch")
                  : ""}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 20,
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "60%" }}>
                  <span
                    style={{
                      color:
                        data.vehicleRegistrationMark &&
                        data.vehicleRegistrationMark ===
                          application.vehicleRegistrationMark
                          ? themeColor.textColor
                          : themeColor.redTextColor,
                      fontSize: "1.3rem",
                      fontWeight: 500,
                    }}
                  >
                    {t("register.step2.vehicleRegistrationMark")}
                  </span>
                </div>
                <div style={{ width: "40%" }}>
                  {data.vehicleRegistrationMark ? (
                    <span
                      style={{
                        color:
                          data.vehicleRegistrationMark &&
                          data.vehicleRegistrationMark ===
                            application.vehicleRegistrationMark
                            ? themeColor.textColor
                            : themeColor.redTextColor,
                        fontSize: "1.3rem",
                        fontWeight: 500,
                      }}
                    >
                      {data.vehicleRegistrationMark}
                    </span>
                  ) : (
                    <img src={cross_icon} alt="error" style={{ width: 40 }} />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: 30,
                  alignItems: "center",
                }}
              >
                <div style={{ width: "60%" }}>
                  <span
                    style={{
                      color:
                        data.vehicleLicenseExpireDate &&
                        data.vehicleLicenseExpireDate ===
                          application.vehicleLicenseExpireDate
                          ? themeColor.textColor
                          : themeColor.redTextColor,
                      fontSize: "1.3rem",
                      fontWeight: 500,
                    }}
                  >
                    {t("register.step2.expirationDate")}
                  </span>
                </div>
                <div style={{ width: "40%" }}>
                  {data.vehicleLicenseExpireDate ? (
                    <span
                      style={{
                        color:
                          data.vehicleLicenseExpireDate &&
                          data.vehicleLicenseExpireDate ===
                            application.vehicleLicenseExpireDate
                            ? themeColor.textColor
                            : themeColor.redTextColor,
                        fontSize: "1.3rem",
                        fontWeight: 500,
                      }}
                    >
                      {data.vehicleLicenseExpireDate}
                    </span>
                  ) : (
                    <img src={cross_icon} alt="error" style={{ width: 40 }} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 20,
              marginBottom: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => {
                handleClose();
              }}
            >
              {t("register.confirm")}
            </Button>
          </div>
          <div
            style={{
              marginTop: 20,
              marginBottom: 30,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h5 style={{ color: themeColor.textColor }}>
              {t("register.step2.importantNoteTitle")}
            </h5>
            <p style={{ maxWidth: "90%", color: themeColor.textColor }}>
              {t("register.step2.importantNoteContent")}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OCRWarningModal;
