import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";
import Button from "../../components/general/button";
import AnimatedIcons from "../../components/general/animatedIcons";
import themeColor from "../../styles/themeColor";

const FailPage = withRouter((props) => {
  const { handleClose, content } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          display: "flex",
          padding: 20,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <AnimatedIcons.FailIcon />
        <span
          style={{
            color: themeColor.textColor,
            fontSize: "1.2rem",
            fontWeight: 500,
          }}
        >
          {content || t("authentication.fail")}
        </span>
      </div>
      <div
        style={{
          marginTop: 20,
          marginBottom: 30,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          {t("authentication.close")}
        </Button>
      </div>
    </div>
  );
});

export default FailPage;
