export default {
  home: {
    title: "Online Ballot System",
    register: "REGISTER",
    login: "Staff Login",
  },
  authentication: {
    staffLogin: "Staff Login",
    login: "Login",
    logout: "Log Out",
    staffNumber: "Staff Number",
    notStaff: "Not Staff",
    fail: "Login Failed",
    close: "Close",
    unauthorized: "Forbidden: IP address rejected.",
  },
  header: {
    companyName: "Transport Department",
    title: "Transport Department Multi-storey Car Park",
    firstHalf:
      "<newline/>Submission of application for Quarterly Parking Service, and ",
    payLink: "PAYMENT LINK ",
    secondHalf:
      " applicable to successful ballot applicant<newline/>(for details, please refer to the “Guidance Notes for Quarterly Parking Service Application”)",
  },
  register: {
    step1: {
      title: "Select Carpark",
      campaignTitle: "Quarterly Parking Service",
      available: " - Application Now Open",
      coming: " - Application Opening Soon",
      HK: "HK",
      KLN: "KLN",
      NT: "NT",
      from: "From",
      to: "To",
      submissionDate: "Submission Date: ",
      noResult: "No Result",
      staff: {
        title: "Upload Application Form",
        form: "Application Form",
        uploadFail: "Upload Failed",
        retry: "Reupload Application Form",
        uploadSuccess: "Upload Success",
        appNumber: "Application Number",
        notFound: "Record not found.",
        carparkDetail: "Carpark Detail",
        error: "Error! Please try again.",
        carpark: "Carpark",
        region: "Region",
        campaign: "Campaign",
        parkingPeriod: "Parking Period",
        submissionDate: "Submission Date",
        vehicleType: "Vehicle Type",
      },
    },
    step2: {
      title: "Upload Document",
      upload: "UPLOAD",
      vehicleLicence: "Vehicle Licence",
      confirmInfo: "Please confirm if the following information is correct:",
      vehicleRegistrationMark: "Vehicle Registration Mark:",
      expirationDate: "Licence Expires:",
      vehicleType: "Class of Vehicle:",
      failToRecognise: "The following information cannot be recognised:",
      retry: "Reupload Document",
      amendInfo: "(The above information can be amended in the next page.)",
      supportedFormat: "[File Formats: jpg, jpeg, png (not exceeding 8 MB)]",
      importantNoteTitle: "Important Note",
      importantNoteContent:
        "Vehicle registration mark, licence expiry date and vehicle class are essential information for processing the application. If the system cannot identify the vehicle registration mark, licence expiry date or vehicle class correctly from your uploaded document, please submit your application for quarterly parking service manually through the shroff office of the respective car park. ",
      close: "Close",
      vehicleLicenseReminderTitle: "Remarks:",
      vehicleLicenseReminder:
        "Please upload the「Vehicle License」vertically as per the specifications of the sample",
      sampleImage: "Sample Image",
      unacceptableImage: {
        example: "Examples of Unacceptable Photos",
        incomplete: "Incomplete vehicle licence",
        irregular: "Asymmetric vehicle licence",
        shadow: "Shadowed image",
        tooSmall: "Image resolution is too low",
        tooManyBackground: "Excessive background area",
      },
    },
    step3: {
      title: "Fill-in Information",
      vehicleRegistrationMark: "Vehicle Registration Mark",
      vehicleLicenseExpireDate: "Licence Expires",
      carType: "Class of Vehicle",
      carTypeRemark:
        "(You are required to select the correct Class of Vehicle. Otherwise, your application will be rejected.)",
      applicantName: "Registered owner/Applicant's Name",
      email: "Email Address",
      phone: "Contact Number",
      physicalDisability:
        "Registered owner/applicant is a person with physical disability:",
      yes: "Yes",
      no: "No",
      upload: "UPLOAD DOCUMENT",
      plzUpload: "Please upload",
      parkingPermit: "Disabled Person's Parking Permit",
      or: "or",
      registrationCard: "the letter of approval",
      consentRemarks_1: "I have read carefully, understood and agreed to the ",
      consentRemarks_2: "“Personal Information Collection Statement“",
      consentRemarks_3: " and the ",
      consentRemarks_4: "“Requirements for Application”",
      consentRemarks_5: " as stated on the ",
      consentRemarks_6:
        "“Guidance Notes for Purchasing Quarterly Parking Service”.",
      warning: {
        vehicleRegistrationMark: {
          empty: "Please enter Vehicle Registration Mark",
          notMatch: "One of the information below is not match.",
          notRecognised: "The following information cannot be recognised:",
          invalid: "Format of the Vehicle Registration Mark is incorrect.",
        },
        carType: "Please choose Car Type",
        applicantName: "Please enter Applicant's Name",
        email: "Please enter Email Address",
        phone: "Please enter Telephone Number",
        disablePersonParkingPermit:
          "Please upload Disable Person Parking Permit or the letter of approval",
        isAgreeConsent:
          "Please read and agree the “Requirement for Application”",
        confirmEmail: {
          empty: "Please enter Confirm Email Address",
          notMatch: "Confirm Email Address not match with Email Address",
        },
        invalidPhone: "Invalid Telephone Number",
        invalidEmail: "Invalid Email Address",
        vehicleLicenseExpireDate: "Please enter Licence Expires",
      },
      remark: "Remarks (no need to fill up unless with special circumstance)",
      confirmEmail: "Confirm Email Address",
      amendInfo:
        "*Amendment(s) in any of these field(s) will be subject to manual verification.",
    },
    step4: {
      title: "Confirm",
      successTitle: "Application Submitted",
      applicationNumber: "Your application number is ",
      na: "N/A",
      failTitle: "Submission Failed!",
      tryAgain: "Please try again.",
    },
    next: "NEXT",
    back: "BACK",
    finish: "FINISH",
    confirm: "CONFIRM",
    errorMsg: {
      submitted: "This application number already submitted.",
      carparkNoCampaign: "No running campaign for this carpark.",
      licenceExpired:
        "The system cannot process your application due to the expired vehicle license. For any assistance, please visit the shroff office of the respective car park or call at 2804 2600.",
      campaignExpired: "Campaign is not running.",
      alreadyRegistered: "Vehicle Registration Mark already registered.",
      notFound: "Record not found.",
      invalidFileFormat: "Invalid file format.",
    },
    overMaxImageLimit: "File must be less than 8MB",
    invalidImageFormat: "Invalid image format",
  },
};
