import React from "react";
import "./checkButton.css";
import PropTypes from "prop-types";

const Radio = (props) => {
  const { checked, onChange, name, value, style } = props;
  return (
    <div>
      <input
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="custom-radio-button checkmark" style={{ ...style }} />
    </div>
  );
};

Radio.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
};

export default Radio;
