const ActionTypes = {
  // Account
  ACCOUNT_LOGIN: "Account/LOGIN",
  ACCOUNT_LOGOUT: "Account/LOGOUT",

  // Application
  APPLICATION_UPDATE: "Application/UPDATE",
  APPLICATION_RESET: "Application/RESET",
  APPLICATION_UPLOAD_ATTACHMENT: "Application/UPLOAD_ATTACHMENT",
  APPLICATION_SUBMIT: "Application/SUBMIT",
  APPLICATION_OCR: "Application/OCR",

  // Announcement
  ANNOUNCEMENT_GET_LIST: "Announcement/GET_LIST",

  // Campaign
  CAMPAIGN_GET_LIST: "Campaign/GET_LIST",

  // Vehicle Type
  VEHICLE_GET_LIST: "Vehicle/GET_LIST",
};

export default ActionTypes;
