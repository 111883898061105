import React from "react";
import Carousel from "../../components/general/carousel";
import Modal from "../../components/general/modal";
import close_icon from "../../assets/images/register/step1/cross.png";
import themeColor from "../../styles/themeColor";
import "moment/locale/zh-hk";
import "moment/locale/en-gb";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isValidUrl } from "../../helpers/generalHelper";
import LangSwitch from "../../components/general/langSwitch";

const NewsModal = (props) => {
  const { show, handleClose } = props;
  const { i18n } = useTranslation();
  const announcements = useSelector((state) => state.announcement);

  return (
    <Modal show={show} onHide={handleClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: themeColor.lightOptionColor,
          borderRadius: 15,
          boxShadow: `0px 3px 6px ${themeColor.shadowGrey}`,
          border: `1px solid ${themeColor.borderColor}`,
          width: "95vw",
          alignSelf: "center",
          paddingBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "20px 20px 0 20px",
          }}
        >
          <LangSwitch />
          <div>
            <button
              type="button"
              style={{ background: "transparent", border: "none" }}
              onClick={() => {
                handleClose();
              }}
            >
              <img
                src={close_icon}
                alt="close"
                style={{
                  objectFit: "contain",
                  width: 30,
                  float: "right",
                }}
              />
            </button>
          </div>
        </div>
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <Carousel>
            {announcements.list
              .filter((announcement) => announcement.status === "publish")
              .map((announcement) => {
                const image =
                  (i18n.language === "zh"
                    ? announcement.image_zh
                    : announcement.image) || {};
                if (isValidUrl(image.fileUrl)) {
                  return (
                    <Carousel.Item>
                      <a
                        href={
                          i18n.language === "zh"
                            ? announcement.url_zh
                            : announcement.url
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={image.fileUrl}
                          alt={
                            i18n.language === "zh"
                              ? announcement.subject_zh
                              : announcement.subject
                          }
                          style={{
                            width: "80vw",
                            height: "80vh",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                      <Carousel.Caption>
                        <div
                          style={{
                            backgroundColor: themeColor.backgroundColor,
                            opacity: 0.95,
                            padding: 20,
                          }}
                        >
                          <a
                            href={
                              i18n.language === "zh"
                                ? announcement.url_zh
                                : announcement.url
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <h4 style={{ color: themeColor.textColor }}>
                              {i18n.language === "zh"
                                ? announcement.subject_zh
                                : announcement.subject}
                            </h4>
                          </a>
                          <div
                            style={{
                              color: themeColor.textColor,
                              overflowY: "scroll",
                              maxHeight: "30vh",
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                i18n.language === "zh"
                                  ? announcement.content_zh
                                  : announcement.content,
                            }}
                          />
                        </div>
                      </Carousel.Caption>
                    </Carousel.Item>
                  );
                }
                return (
                  <Carousel.Item>
                    <div
                      style={{
                        backgroundColor: themeColor.backgroundColor,
                        opacity: 0.8,
                        height: "80vh",
                        width: "80vw",
                        padding: 20,
                      }}
                    >
                      <a
                        href={
                          i18n.language === "zh"
                            ? announcement.url_zh
                            : announcement.url
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h4 style={{ color: themeColor.textColor }}>
                          {i18n.language === "zh"
                            ? announcement.subject_zh
                            : announcement.subject}
                        </h4>
                      </a>
                      <div
                        style={{
                          color: themeColor.textColor,
                          overflowY: "scroll",
                          maxHeight: "80vh",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            i18n.language === "zh"
                              ? announcement.content_zh
                              : announcement.content,
                        }}
                      />
                    </div>
                  </Carousel.Item>
                );
              })}
          </Carousel>
        </div>
      </div>
    </Modal>
  );
};

export default NewsModal;
